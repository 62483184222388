import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "inherit",
        padding: 0,
        border: `1px solid ${theme.palette.action.disabled}`
    }
}));

export default useStyles;