export const documentInitialState = {
    items: []
};

export const documentReducer = (state, action) => {
    switch (action.type) {
        case 'list':
            const items = action.items;
            return {
                ...state,
                items
            };
        case 'reset':
            return documentInitialState;
        default:
            throw new Error('Unexpected action');
    }
}
