import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        marginRight: theme.spacing(2)
    },
    elementContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export default useStyles;