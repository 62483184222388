import React, { useState } from 'react';
import PropTypes from 'prop-types';

import i18n from 'i18n-js';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Delete from '@material-ui/icons/Delete';

import useStyles from './DeleteModal.style';

export default function DeleteModal({onSubmit}) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        setLoading(true);
        await onSubmit();
    }

    const renderLoading = () => {
        if(!loading) {
          return null;
        }
    
        return (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        );
    }

    return (
        <div>
            <IconButton size="small" onClick={handleClickOpen} style={{ color: '#f44336' }}>
                <Delete />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{i18n.t('deleteModalTitle')}</DialogTitle>
                <DialogContent>
                    {renderLoading()}
                    <DialogContentText>
                        {i18n.t('deleteModalInfo')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {i18n.t('deleteModalBackButtonText')}
                    </Button>
                    <Button
                        className={classes.submitButton}
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                        type="submit"
                        disableElevation
                    >
                        {i18n.t('deleteModalSubmitButtonText')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

DeleteModal.propTypes = {
    onSubmit: PropTypes.func
}

DeleteModal.defaultProps = {
    onSubmit: () => {}
}
