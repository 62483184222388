import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    hotelPage: {
        marginTop: theme.spacing(4),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
}));

export default useStyles;