import React from 'react';
import PropTypes from 'prop-types';

import Rating from '@material-ui/lab/Rating';

export default function CRating({documentId, value, userId, onCreateRating, onUpdateRating, isDisabled}) {
    const handleChange = (e, score) => {
        if(score) {
            if(userId) {
                onUpdateRating({documentId, score});
            } else {
                onCreateRating({documentId, score});
            }
        }
    }

    return (
        <Rating
            name={`rating-${documentId}`}
            defaultValue={value}
            max={3}
            onChange={handleChange}
            disabled={isDisabled}
        />
    )
}

CRating.propTypes = {
    documentId: PropTypes.string,
    isDisabled: PropTypes.bool,
    userId: PropTypes.string,
    value: PropTypes.number,
    onCreateRating: PropTypes.func,
    onUpdateRating: PropTypes.func
}

CRating.defaultProps = {
    documentId: null,
    userId: null,
    isDisabled: false,
    value: 0,
    onCreateRating: () => {},
    onUpdateRating: () => {}
}