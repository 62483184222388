import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { API, graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../models/graphql/mutations";

import {
  listDocuments,
  resetDocuments,
} from "../../models/actions/documentActions";

import { DropzoneArea } from "material-ui-dropzone";

import i18n from "i18n-js";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import useStyles from "./NewDocumentModal.style";

export default function NewDocumentModal({ category, onSubmit, linkOnly = false }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [isLink, setIsLink] = useState("false");
  const [url, setUrl] = useState("");
  const [linkType, setLinkType] = useState("VIDEO");
  const [files, setFiles] = useState([]);

  const _createPresignedUrl = async (documentId, fileType, name) => {
    try {
      const mutation = await API.graphql(
        graphqlOperation(mutations.createPresignedUrl, {
          input: { documentId, fileType, name },
        })
      );
      return mutation.data.createPresignedUrl;
    } catch (error) {
      console.log(error);
    }
  };

  const _uploadToS3 = async (file, presignedUrl) => {
    const { name, type } = file;
    try {
      const formData = new FormData();
      formData.append("file", file, name);
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": type },
        body: formData,
      };
      const response = await fetch(presignedUrl, requestOptions);
      return {
        versionId: response.headers.get("x-amz-version-id"),
      };
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFile = async (documentId, file) => {
    const { name, type, size } = file;

    const presignedUrlData = await _createPresignedUrl(documentId, type, name);
    const { presignedUrl, bucket, key } = presignedUrlData;

    const uploadedData = await _uploadToS3(file, presignedUrl);
    const { versionId } = uploadedData;

    const input = {
      documentId,
      title: name,
      language: "it",
      s3File: {
        bucket,
        fileType: type,
        key,
        size,
        versionId,
      },
    };

    try {
      await API.graphql(graphqlOperation(mutations.createFile, { input }));
    } catch (e) {
      console.error(e);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleTypeChange = (e) => {
    setIsLink(e.target.value);
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleLinkTypeChange = (e) => {
    setLinkType(e.target.value);
  };

  const handleFilesChange = (selectedFiles) => {
    setFiles(selectedFiles);
  };

  const handleCreateDocument = async () => {
    if (name === "" || !files.length) {
      setError(i18n.t("submitValidation"));
      return;
    }

    setLoading(true);

    const mutation = await API.graphql(
      graphqlOperation(mutations.createDocument, {
        input: {
          name,
          documentCategoryId: category.id,
          state: "APPROVED",
          expireOn: "2099-12-31T23:59:59.999Z",
        },
      })
    );

    const result = mutation.data.createDocument;

    for (let i = 0; i < files.length; i++) {
      await uploadFile(result.id, files[i]);
    }

    dispatch(resetDocuments("concur"));
    dispatch(listDocuments(category.id, "concur"));

    setLoading(false);
    setOpen(false);
    setError(null);
  };

  const handleCreateLink = async () => {
    setLoading(true);

    await API.graphql(
      graphqlOperation(mutations.createLink, {
        input: {
          name,
          url,
          type: linkType,
          linkCategoryId: category.id,
        },
      })
    );

    onSubmit();

    setLoading(false);
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLink === "true") {
      await handleCreateLink();
    } else {
      await handleCreateDocument();
    }
  };

  const renderLoading = () => {
    if (!loading) {
      return null;
    }

    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {i18n.t("modalButtonText")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {i18n.t("modalTitle")}
          </DialogTitle>
          <DialogContent>
            {error ? <p className={classes.error}>{error}</p> : null}
            {renderLoading()}
            <DialogContentText>{i18n.t("modalInfo")}</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="name"
                  label={i18n.t("modalInputPlaceholder")}
                  type="text"
                  fullWidth
                  required={true}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" className="mb-2">
                    {i18n.t("modalType")}
                  </FormLabel>
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={isLink}
                    onChange={handleTypeChange}
                  >
                    <FormControlLabel
                      value={"false"}
                      control={<Radio />}
                      label="File"
                    />
                    <FormControlLabel
                      value={"true"}
                      control={<Radio />}
                      label="Link"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {isLink === "true" ? (
                  <>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      id="url"
                      label="URL"
                      type="text"
                      fullWidth
                      required={true}
                      onChange={handleUrlChange}
                    />
                    <FormControl
                      id="mediaType"
                      variant="outlined"
                      fullWidth
                      required={true}
                      margin="dense"
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Link Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Link Type"
                        onChange={handleLinkTypeChange}
                      >
                        <MenuItem value="VIDEO">VIDEO</MenuItem>
                        <MenuItem value="DOCUMENT">DOCUMENT</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <DropzoneArea
                    filesLimit={1}
                    maxFileSize={100000000} //100 Mb
                    acceptedFiles={[
                      "image/jpeg",
                      "image/png",
                      "application/pdf",
                    ]}
                    dropzoneText={i18n.t("modalDropzoneText")}
                    showFileNames={true}
                    showAlerts={false}
                    required={true}
                    onChange={handleFilesChange}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {i18n.t("modalBackButtonText")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disableElevation
            >
              {i18n.t("modalSubmitButtonText")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

NewDocumentModal.propTypes = {
  category: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

NewDocumentModal.defaultProps = {
  onSubmit: () => {},
};
