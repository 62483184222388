import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';

import {
  listDocuments,
  resetDocuments,
  createDocumentRating,
  updateDocumentRating
} from '../../../models/actions/documentActions';
import Can from '../../../config/Can';
import CTable from '../../../components/CTable/CTable';

export default function CTableContainer({onNameClick}) {
  const dispatch = useDispatch();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [ filteredDocuments, setFilteredDocuments ] = useState([]);

  let { id } = useParams();
  const userMode = useSelector(state => state.auth.userMode);
  const documents = useSelector(state => state.documents.items);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
  }
    
  const getComparator = (order, orderBy) => {
    return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  const stableSort = (array, comparator) => { 
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRatingCreate = (input) => {
    dispatch( createDocumentRating(input) )
  }

  const handleRatingUpdate = (input) => {
    dispatch( updateDocumentRating(input) )
  }

  const fetchData = useCallback(() => {
    dispatch( listDocuments(id) )
  }, [dispatch, id])

  useEffect(() => {
    fetchData();
  }, [fetchData])

  useEffect(() => {
    setFilteredDocuments(documents);
  }, [documents])

  useEffect(() => {
    if(documents && userMode) {
      setFilteredDocuments( documents.filter(d => d.state === "APPROVED") );
    } else {
      setFilteredDocuments( documents );
    }
  }, [documents, userMode])

  useEffect(() => {
    dispatch( resetDocuments() );
  }, [dispatch])

  const CanComponent = ({children}) => (
    <Can I="view" a="DocumentsTableState">{children}</Can>
  )

  return (
    <CTable
        CanComponent={CanComponent}
        order={order}
        orderBy={orderBy}
        documents={filteredDocuments}
        compare={getComparator}
        sort={stableSort}
        onSorting={handleRequestSort}
        onNameClick={onNameClick}
        onCreateRating={handleRatingCreate}
        onUpdateRating={handleRatingUpdate}
    />
  );
}

CTableContainer.propTypes = {
  onNameClick: PropTypes.func
}

CTableContainer.defaultProps = {
  onNameClick: () => {}
}