import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import i18n from "i18n-js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";


import { green, red, grey } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/Help';

import CTableHead from "./components/CTableHead";
import Rating from "./components/CRating";
import useStyles from "./CTable.style";

export default function CTable(props) {
  const {
    hasHotels,
    CanComponent,
    documents,
    order,
    orderBy,
    compare,
    sort,
    onSorting,
    onNameClick,
    onCreateRating,
    onUpdateRating,
  } = props;

  const classes = useStyles();

  const numDaysBetween = (d1, d2) => {
    var diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
  };

  const calculateDaysLeft = (date) => {
    return Math.round(numDaysBetween(new Date(date), new Date()));
  };

  const renderPriceRange = (document) => {
    let priceRange = ""
    if (document.properties.priceRange.value[1] === 0) {
      if (!document.properties.priceRange.description) {
        priceRange += `N/A`;
      }
    } else {
      if (document.properties.priceRange.value[0] === 0) {
        priceRange += `${document.properties.priceRange.value[1]} ${document.properties.priceRange.currency}`;
      } else {
        priceRange += `${document.properties.priceRange.value[0]}-${document.properties.priceRange.value[1]} ${document.properties.priceRange.currency}`;
      }
    }
    return (
      <>
        {priceRange && (
          <>
            {priceRange}
            <br />
          </>
        )}
        {document.properties.priceRange.description && (
          document.properties.priceRange.description
        )}
      </>
    );
  }

  const renderHasRestaurant = (document) => {
    if (document.properties.hasRestaurant) {
      return (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <CheckCircleIcon fontSize="small" style={{ color: green[500] }} />
          <span className={classes.restaurantText}>{i18n.t("tableHasRestaurantAvailable")}</span>
        </div>
      );
    } else {
      if (document.properties.hasRestaurant === false) {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
            <CancelIcon fontSize="small" style={{ color: red[500] }} />
            <span className={classes.restaurantText}>{i18n.t("tableHasRestaurantUnavailable")}</span>
          </div>
        );
      } else {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
            <span className={classes.restaurantText}>{i18n.t("tableHasRestaurantUnknown")}</span>
          </div>
        );
      }
    }
  };

  const renderRating = (document) => {
    const rating = document.rating ? parseFloat(document.rating.toFixed(1)) : 0;

    return (
      <div className={classes.rating}>
        <b className={classes.ratingText}>{rating}</b>
        <Rating isDisabled={true} value={rating} />
      </div>
    );
  };

  const renderUserRating = (document) => {
    const userId = document.ratings.items.length
      ? document.ratings.items[0].userId
      : null;
    const score = document.ratings.items.length
      ? parseFloat(document.ratings.items[0].score)
      : 0;

    return (
      <div className={classes.userRating}>
        <Rating
          documentId={document.id}
          userId={userId}
          value={score}
          onCreateRating={onCreateRating}
          onUpdateRating={onUpdateRating}
        />
        <div className={classes.userRatingText}>Lascia la tua valutazione</div>
      </div>
    );
  };

  const renderState = (state, expireOn) => {
    const isExpiring = calculateDaysLeft(expireOn) <= 90;

    switch (state) {
      case "DRAFT":
        return isExpiring
          ? i18n.t("tableExpiringText")
          : i18n.t("tableDraftText");
      case "APPROVED":
        return isExpiring
          ? i18n.t("tableExpiringText")
          : i18n.t("tableApprovedText");
      default:
        return i18n.t("tableExpiredText");
    }
  };

  const renderExpiring = (state, expireOn) => {
    const lastDays = calculateDaysLeft(expireOn);

    if (lastDays > 18250) {
      return <div>-</div>;
    }

    var expiringText = i18n.t("tableExpiringDays", { days: lastDays });

    if (state === "EXPIRED") {
      expiringText = i18n.t("tableExpirationDays", { days: lastDays });
    }

    return (
      <>
        {new Date(expireOn).toLocaleDateString()}
        {lastDays <= 90 ? (
          <div className={classes.expiring}>{expiringText}</div>
        ) : null}
      </>
    );
  };

  const renderBookingOption = (bookingOption, email) => {
    let color= "rgb(224 137 26)";
    let link = `mailto:${email}`
    let label = i18n.t("tableBookingEmailLabel");
    if (bookingOption === "CONCUR") {
      link = "https://myapps.microsoft.com/signin/8bc51afb-361c-4e2e-abc5-e79a725f7617?tenantId=6d4ce582-8ee5-4852-ac25-5a500c9f16c9";
      label = i18n.t("tableBookingConcurLabel");
      color= "#1E68A3";
    }

    return (
      <a
        href={link}
        target="_blank"
        style={{ textDecoration: "none" }}
        rel="noopener noreferrer"
      >
        <Button
          style={{backgroundColor: color, color: "#FFF"}}
          variant="contained"
          disableElevation
          endIcon={<Icon>open_in_new</Icon>}
        >
          {label}
        </Button>
      </a>
    );
  };

  const getStateColor = (state, expireOn) => {
    const isExpiring = calculateDaysLeft(expireOn) <= 90;

    switch (state) {
      case "DRAFT":
        return isExpiring ? classes.expired : classes.draft;
      case "APPROVED":
        return isExpiring ? classes.expired : classes.approved;
      default:
        return classes.expired;
    }
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table}>
        <CTableHead
          hasHotels={hasHotels}
          CanComponent={CanComponent}
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={onSorting}
        />
        <TableBody>
          {sort(documents, compare(order, orderBy)).map((document, index) => {
            return (
              <TableRow hover key={document.id}>
                <TableCell
                  className={classes.name}
                  onClick={() => onNameClick(document.id)}
                >
                  <b>{document.name}</b>
                </TableCell>
                <CanComponent>
                  <TableCell
                    className={clsx(
                      classes.state,
                      getStateColor(document.state, document.expireOn)
                    )}
                  >
                    <b>{renderState(document.state, document.expireOn)}</b>
                  </TableCell>
                </CanComponent>
                <TableCell>
                  <Typography noWrap>{document.province}</Typography>
                </TableCell>
                <TableCell className={classes.address}>
                  <Typography variant="body2">{document.address}</Typography>
                </TableCell>
                {hasHotels && (
                  <TableCell>
                    {renderPriceRange(document)}
                  </TableCell>
                )}
                {hasHotels && (
                  <TableCell>
                    {renderHasRestaurant(document)}
                  </TableCell>
                )}
                <TableCell>{renderUserRating(document)}</TableCell>
                <TableCell>{renderRating(document)}</TableCell>
                {hasHotels &&
                  <TableCell>{renderBookingOption(document.properties.bookingOption, document.email)}</TableCell>
                }
                {!hasHotels &&
                  <>
                    <TableCell>{renderExpiring(document.state, document.expireOn)}</TableCell>
                    <TableCell>{document.note}</TableCell>
                  </>
                }
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CTable.propTypes = {
  hasHotels: PropTypes.bool,
  documents: PropTypes.array,
  CanComponent: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  compare: PropTypes.func,
  sort: PropTypes.func,
  onSorting: PropTypes.func,
  onNameClick: PropTypes.func,
  onCreateRating: PropTypes.func,
  onUpdateRating: PropTypes.func,
};

CTable.defaultProps = {
  hasHotels: false,
  documents: [],
  CanComponent: ({ children }) => <>{children}</>,
  order: "asc",
  orderBy: "name",
  compare: () => { },
  sort: (documents) => {
    return documents;
  },
  onSorting: () => { },
  onNameClick: () => { },
  onCreateRating: () => { },
  onUpdateRating: () => { },
};
