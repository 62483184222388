import { API, graphqlOperation } from "@aws-amplify/api";
import * as queries from '../graphql/queries';
import {
    LIST_CATEGORIES_REQUEST,
    LIST_CATEGORIES_SUCCESS,
    LIST_CATEGORIES_FAILURE,
    GET_CATEGORY_REQUEST,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILURE,
    RESET_CATEGORY
} from './types';

export const listCategories = (isHotels) => async dispatch => {
    dispatch({ type: LIST_CATEGORIES_REQUEST });
    try {
        const operation = isHotels ? queries.listCategoriesForHotels : queries.listCategories;
        const query = await API.graphql(graphqlOperation(operation , {
            filter: { macrocategoryId: { eq: process.env.REACT_APP_MACROCATEGORY_ID } }
        }));

        dispatch({
            type: LIST_CATEGORIES_SUCCESS,
            payload: query.data.listCategories.items
        });
    } catch(e) {
        console.error(e);
        dispatch({
            type: LIST_CATEGORIES_FAILURE,
            payload: e
        });
    }
}

export const getCategory = (id) => async dispatch => {
    dispatch({ type: GET_CATEGORY_REQUEST });
    try {
        const query = await API.graphql(graphqlOperation(queries.getCategory, { id }));

        dispatch({
            type: GET_CATEGORY_SUCCESS,
            payload: query.data.getCategory
        });
    } catch(e) {
        console.error(e);
        dispatch({
            type: GET_CATEGORY_FAILURE,
            payload: e
        });
    }
}

export const resetCategory = () => async dispatch => {
    dispatch({ type: RESET_CATEGORY });
}