import { API, graphqlOperation } from "@aws-amplify/api";
import * as mutations from '../graphql/mutations';

import {
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_FAILURE
} from './types';

const _createPresignedUrl = async (documentId, fileType, name) => {
    try {
        const mutation = await API.graphql(graphqlOperation(mutations.createPresignedUrl, {
            input: { documentId, fileType, name}
        }));
        return mutation.data.createPresignedUrl;
    } catch(error) {
        console.log(error);
    }
}

const _uploadToS3 = async (file, presignedUrl) => {
    const { name, type } = file;
    try {
        const formData = new FormData(); 
        formData.append("file", file, name); 
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': type },
            body: formData
        };
        const response = await fetch(presignedUrl, requestOptions);
        return {
            versionId: response.headers.get("x-amz-version-id")
        };
    } catch(error) {
        console.error(error);
    }
}

export const uploadFile = (documentId, file) => async dispatch => {
    dispatch({ type: UPLOAD_FILE_REQUEST });

    const { name, type, size } = file;

    const presignedUrlData = await _createPresignedUrl(documentId, type, name);
    const { presignedUrl, bucket, key } = presignedUrlData;

    const uploadedData = await _uploadToS3(file, presignedUrl);
    const { versionId } = uploadedData;

    const input = {
        documentId,
		title: name, 
		language: "it",
		s3File: { 
			bucket, 
			fileType: type,
            key,
            size,
            versionId
		}
    }

    try {
        const mutation = await API.graphql(graphqlOperation(mutations.createFile, {input}));
        dispatch({
            type: UPLOAD_FILE_SUCCESS,
            payload: mutation.data.createFile
        });
    } catch(e) {
        console.error(e);
        dispatch({
            type: UPLOAD_FILE_FAILURE,
            payload: e
        });
    }
}

export const deleteFile = (id) => async dispatch => {
    dispatch({ type: DELETE_FILE_REQUEST });
    try {
        const mutation = await API.graphql(graphqlOperation(mutations.deleteFile, {input: {id} }));
        dispatch({
            type: DELETE_FILE_SUCCESS,
            payload: mutation.data.deleteFile
        });
    } catch(e) {
        console.error(e);
        dispatch({
            type: DELETE_FILE_FAILURE,
            payload: e
        });
    }
}