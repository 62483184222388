import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import i18n from "i18n-js";

import { useSelector } from "react-redux";

import ReactMarkdown from "react-markdown";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";

import UserMap from "./components/UserMap/UserMap";
import UserModalSkeleton from "./components/UserModalSkeleton/UserModalSkeleton";
import useStyles from "./UserModal.style";

export default function UserModal({ title, open, onClose, isHotel }) {
  const classes = useStyles();
  const category = useSelector((state) => state.categories.item);
  const document = useSelector((state) => state.documents.item);

  const renderInfo = () => {
    if (!document.description) {
      return null;
    }

    return (
      <>
        <Typography variant="subtitle1">
          <b>{i18n.t("informationText")}</b>
        </Typography>
        {isHotel ? (
          <>
            {" "}
            <p>
              <b>{`${i18n.t("tablePriceRangeLabel")} `}</b>
              {renderPriceRange()}
            </p>
            <p className="mb-4">
              <b>{`${i18n.t("tableHasRestaurantLabel")} `}</b>
              {document.properties.hasRestaurant
                ? i18n.t("tableHasRestaurantAvailable")
                : i18n.t("tableHasRestaurantUnavailable")}
            </p>
          </>
        ) : null}
        <ReactMarkdown source={document.description} />
      </>
    );
  };

  const renderPriceRange = () => {
    let text = "";
    if (document.properties.priceRange.value[1] === 0) {
      if (!document.properties.priceRange.description) {
        text += `N/A`;
      }
    } else {
      if (document.properties.priceRange.value[0] === 0) {
        text += `${document.properties.priceRange.value[1]} ${document.properties.priceRange.currency}`;
      } else {
        text += `${document.properties.priceRange.value[0]}-${document.properties.priceRange.value[1]} ${document.properties.priceRange.currency}`;
      }
    }
    if (document.properties.priceRange.description) {
      text += ` ${document.properties.priceRange.description}`;
    }
    return text;
  }

  const renderContacts = () => {
    if (!document.phone && !document.email && !document.website) {
      return null;
    }

    return (
      <>
        <Typography variant="subtitle1">
          <b>{i18n.t("contactsText")}</b>
        </Typography>
        {document.phone && (
          <p style={{ display: "flex" }}>
            <Icon fontSize="small" style={{ marginRight: 5 }}>
              phone
            </Icon>
            {`${document.phone}`}
          </p>
        )}
        {document.email && (
          <p style={{ display: "flex" }}>
            <Icon fontSize="small" style={{ marginRight: 5 }}>
              email
            </Icon>
            {`${document.email}`}
          </p>
        )}
        {document.website && (
          <p style={{ display: "flex" }}>
            <Icon fontSize="small" style={{ marginRight: 5 }}>
              website
            </Icon>
            <a
              href={`//${document.website}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {document.website}
            </a>
          </p>
        )}
      </>
    );
  };

  const renderPosition = () => {
    if (
      !document.address &&
      (!document.location || document.location === "0.0,0.0")
    ) {
      return null;
    }

    var coords = "";
    var lat = 0.0;
    var lng = 0.0;

    if (document.location) {
      coords = document.location.split(",");
      lat = parseFloat(coords[0]);
      lng = parseFloat(coords[1]);
    }

    return (
      <>
        <Typography variant="subtitle1">
          <b>{i18n.t("addressText")}</b>
        </Typography>
        {document.address && (
          <p style={{ display: "flex" }}>
            <Icon fontSize="small" style={{ marginRight: 5 }}>
              room
            </Icon>
            {`${document.address}`}
          </p>
        )}
        {document.location && document.location !== "0.0,0.0" && (
          <div>
            <p className="my-2">
              <a
                style={{ display: "flex" }}
                href={`https://www.google.com/maps/place/${lat},${lng}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon fontSize="small" style={{ marginRight: 5 }}>
                  open_in_new
                </Icon>
                {i18n.t("mapsText")}
              </a>
            </p>
            <UserMap location={document.location} />
          </div>
        )}
      </>
    );
  };

  const renderNote = () => {
    if (!document.note) {
      return null;
    }

    return (
      <>
        <Typography variant="subtitle1">
          <b>{i18n.t("notesText")}</b>
        </Typography>
        <p>{document.note}</p>
      </>
    );
  };

  const renderAssociatedFiles = () => {
    if (!document.id) {
      return null;
    }
    if (!document.files.items.length) {
      return null;
    }

    document.files.items.sort((first, second) => {
      if (first.createdAt < second.createdAt) {
        return -1;
      }
      if (first.createdAt > second.createdAt) {
        return 1;
      }
      return 0;
    });

    return (
      <>
        <Typography variant="subtitle1">
          <b>Files</b>
        </Typography>
        <div className={classes.files}>
          {document.files.items.map((file, index) => (
            <Chip
              key={index}
              className={classes.file}
              icon={<Icon>visibility</Icon>}
              label={
                <a
                  className={classes.fileLink}
                  href={file.s3File.s3PresignedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {file.title}
                </a>
              }
            />
          ))}
        </div>
      </>
    );
  };

  const renderSkeleton = () => {
    if (document.id) {
      return null;
    }
    return <UserModalSkeleton />;
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle
        className={classes.titleContainer}
        style={{ backgroundColor: category.color || "#000" }}
      >
        <Grid container>
          {category.icon ? (
            <Grid item className={classes.titleElement}>
              <Icon style={{ color: "white" }}>{category.icon}</Icon>
            </Grid>
          ) : null}
          <Grid item className={clsx(classes.titleElement, classes.title)}>
            <Typography variant="h5">{document.name}</Typography>
          </Grid>
          <Grid item xs className={classes.close}>
            <IconButton onClick={onClose}>
              <Icon style={{ color: "#fff" }}>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item>{renderSkeleton()}</Grid>
          <Grid item xs={12}>
            {renderInfo()}
          </Grid>
          <Grid item xs={12}>
            {renderNote()}
          </Grid>
          <Grid item xs={12}>
            {renderContacts()}
          </Grid>
          <Grid item xs={12}>
            {renderAssociatedFiles()}
          </Grid>
          <Grid item xs={12}>
            {renderPosition()}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

UserModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isHotel: PropTypes.bool,
};

UserModal.defaultProps = {
  open: false,
  onClose: () => { },
  isHotel: false,
};
