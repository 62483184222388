import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "@aws-amplify/api";

import i18n from "i18n-js";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import {
  getDocument,
  listDocuments,
  resetDocuments,
} from "../../models/actions/documentActions";
import { toggleUsermode } from "../../models/actions/authActions";
import { resetCategory } from "../../models/actions/categoryActions";

import { listLinksByCategory } from "../../models/graphql/queries";

import Can from "../../config/Can";
import TitleBar from "../../components/TitleBar/TitleBar";
import HotelBar from "../../components/HotelBar/HotelBar";
import CTableContainer from "./components/CTableContainer";
import useStyles from "./HotelPage.style";
import Modal from "../../components/Modal/Modal";
import UserModal from "../../components/UserModal/UserModal";
import Form from "../../components/Form/Form";
import Countries from "../HotelPage/components/Risks/Countries";
import Policies from "../HotelPage/components/Risks/Policies";
import NewModal from "../HotelPage/components/Risks/NewModal";

import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import DocumentsBox from "../../components/DocumentsBox";
import NewDocumentModal from "../../components/NewDocumentModal/NewDocumentModal";
import VademecumLink from "./components/Risks/VademecumLink";

export default function HotelPage() {
  const concurCategories = [
    {
      id: "training-pills",
      nameLocalized: { en: "Training pills", it: "Pillole formative" },
    },
    {
      id: "quick-reference-guides",
      nameLocalized: {
        en: "Quick reference guides",
        it: "Quick reference guides",
      },
    },
    {
      id: "manuals",
      nameLocalized: { en: "Manuals", it: "Manuali" },
    },
  ];

  const docandshareCategories = [
    {
      id: "insurance-policies",
      nameLocalized: { en: "Insurance Policies", it: "Polizze Assicurative" },
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [risksCategoryId, setRisksCategoryId] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [concurCategory, setConcurCategory] = useState(concurCategories[0]);
  const [docandshareCategory, setDocandshareCategory] = useState(
    docandshareCategories[0]
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const userMode = useSelector((state) => state.auth.userMode);
  const documents = useSelector((state) => state.documents.items);
  const concurDocuments = useSelector(
    (state) => state.documents.concurDocuments
  );
  const docandshareDocuments = useSelector(
    (state) => state.documents.docandshareDocuments
  );
  const [concurLinks, setConcurLinks] = useState([]);
  const [docandshareLinks, setDocandshareLinks] = useState([]);
  const appMode = process.env.REACT_APP_MODE;

  const listConcurLinks = async (categoryId) => {
    try {
      const query = await API.graphql(
        graphqlOperation(listLinksByCategory, {
          linkCategoryId: categoryId,
        })
      );
      setConcurLinks(query.data.listLinksByCategory.items);
    } catch (error) {
      console.log(error);
    }
  };

  const listDocandshareLinks = async (categoryId) => {
    try {
      const query = await API.graphql(
        graphqlOperation(listLinksByCategory, {
          linkCategoryId: categoryId,
        })
      );
      setDocandshareLinks(query.data.listLinksByCategory.items);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    listConcurLinks(concurCategory.id);
    dispatch(resetDocuments("concur"));
    dispatch(listDocuments(concurCategory.id, "concur"));
  }, [concurCategory, dispatch]);

  useEffect(() => {
    listDocandshareLinks(docandshareCategory.id);
    dispatch(resetDocuments("docandshare"));
    dispatch(listDocuments(docandshareCategory.id, "docandshare"));
  }, [docandshareCategory, dispatch]);

  useEffect(() => {
    dispatch(resetCategory());
  }, [dispatch]);

  const handleOpen = (id) => {
    dispatch(getDocument(id));
    setIsNew(false);
    setModalOpen(true);
  };

  const handleOpenNew = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setIsNew(true);
  };

  const handleConcurLinkSubmit = async () => {
    setConcurLinks([]);
    await listConcurLinks(concurCategory.id);
  };

  const handleDocandshareLinkSubmit = async () => {
    setDocandshareLinks([]);
    await listDocandshareLinks(docandshareCategory.id);
  };

  const handleItemUrlOpen = (item) => {
    if (item.url) {
      window.open(item.url, "_blank");
    } else {
      window.open(item.files.items[0].s3File.s3PresignedUrl, "_blank");
    }
  };

  const handleConcurCategoryChange = (category) => {
    setConcurCategory(category);
  };

  const handleDocandshareCategoryChange = (category) => {
    setDocandshareCategory(category);
  };

  const renderTitle = () => {
    if (isNew) {
      return i18n.t("newTitle");
    }
    return i18n.t("editTitle");
  };

  const renderAdminButton = () => {
    return (
      <Can I="view" a="AdminButton">
        {categoryId ? (
          <Fab
            color={userMode ? "inherit" : "primary"}
            className={classes.fab}
            onClick={() => dispatch(toggleUsermode())}
          >
            <Icon>{userMode ? "edit" : "visibility"}</Icon>
          </Fab>
        ) : null}
      </Can>
    );
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar className="place-content-center">
          <img
            className="place-self-center"
            src="logo.svg"
            alt="Valentino"
            width={140}
          />
        </Toolbar>
      </AppBar>
      <div className={classes.hotelPage}>
        <Can I="view" a="AdminModal">
          <Modal title={renderTitle()} open={modalOpen} onClose={handleClose}>
            <Form
              isNew={isNew}
              onSubmit={handleClose}
              categoryId={categoryId}
              isHotel={true}
            />
          </Modal>
        </Can>
        <Can I="view" a="UserModal">
          <UserModal
            title={"USERMODAL"}
            open={modalOpen}
            onClose={handleClose}
            isHotel={true}
          />
        </Can>

        <div className="md:flex md:flex-column">
          <div className="flex">
            <div className="flex flex-column items-center">
              <Typography variant="h5">{i18n.t("policyTitle")}</Typography>
            </div>
          </div>
        </div>

        <div className="m-8 md:px-8">
          <a
            href={process.env.REACT_APP_TRAVELPOLICY_URL}
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noopener noreferrer"
          >
            <Button
              color="primary"
              variant="contained"
              disableElevation
              endIcon={<Icon>open_in_new</Icon>}
            >
              {i18n.t("policyButton")}
            </Button>
          </a>
        </div>

        <div className="border-b border-gray-400 w-100"></div>

        <div className="md:flex md:flex-column mt-8">
          <div className="flex">
            <div className="flex flex-column items-center">
              <Typography variant="h5">
                {i18n.t("concurDocumentsTitle")}
              </Typography>
            </div>
            <div className="ml-4">
              <Can I="view" a="AdminButton">
                <NewDocumentModal
                  category={concurCategory}
                  onSubmit={handleConcurLinkSubmit}
                />
              </Can>
            </div>
          </div>
        </div>

        <div className="mt-8 md:px-8">
          <DocumentsBox
            categories={concurCategories}
            items={[...concurLinks, ...concurDocuments]}
            onChangeCategory={handleConcurCategoryChange}
            onClick={handleItemUrlOpen}
          />
        </div>

        <div className="border-b border-gray-400 w-100"></div>

        <div className="flex flex-column items-center mt-8">
          <Typography variant="h5">Travel Risks Management</Typography>
          {risksCategoryId && (
            <div className="ml-4">
              <Can I="view" a="AdminButton">
                <Grid item>
                  <NewModal categoryId={risksCategoryId} />
                </Grid>
              </Can>
            </div>
          )}
          <VademecumLink />
        </div>

        <div className="my-8 md:px-8">
          <div className="p-4 bg-gray-200 border border-gray-400 rounded-md">
            <div className="pb-1 font-bold text-lg">
              {i18n.t("trmCourseTitle")}
            </div>
            {i18n.t("trmCourseText.0")}
            <a
              href="mailto:trmlearning.external@valentino.com"
              className="font-bold"
            >
              {i18n.t("trmCourseText.1")}
            </a>
            {i18n.t("trmCourseText.2")}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://valentino-cdn.thron.com/static/GIRDE8_VALENTINO_-_Anagrafica_-_TRM_Q9ESE1.xlsx"
              className="font-bold"
            >
              {i18n.t("trmCourseText.3")}
            </a>
            <br />
            {i18n.t("trmCourseText.4")}
          </div>
        </div>

        <div className="my-8 md:px-8">
          <div className="px-8">
            <Countries onChange={setRisksCategoryId} />
            <Policies categoryId={risksCategoryId} />
          </div>
        </div>

        <div className="border-b border-gray-400 w-100"></div>

        <div className="md:flex md:flex-column mt-8">
          <div className="flex">
            <div className="flex flex-column items-center">
              <Typography variant="h5">
                {i18n.t("insurancePoliciesTitle")}
              </Typography>
            </div>
            <div className="ml-4">
              <Can I="view" a="AdminButton">
                <NewDocumentModal
                  category={docandshareCategory}
                  onSubmit={handleDocandshareLinkSubmit}
                />
              </Can>
            </div>
          </div>
        </div>

        <div className="mt-8 md:px-8">
          <DocumentsBox
            categories={docandshareCategories}
            items={[...docandshareLinks, ...docandshareDocuments]}
            onChangeCategory={handleDocandshareCategoryChange}
            onClick={handleItemUrlOpen}
          />
        </div>

        <div className="mb-8">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {appMode === "welfare" ? (
                <TitleBar onAddClick={handleOpenNew} />
              ) : (
                <HotelBar
                  categoryId={categoryId}
                  onAddClick={handleOpenNew}
                  onChange={setCategoryId}
                />
              )}
            </Grid>
            <Grid item xs={12} className={classes.content}>
              {!documents ? (
                <Typography variant="h6" style={{ color: "#999" }}>
                  {i18n.t("emptyText")}
                </Typography>
              ) : (
                <CTableContainer
                  onNameClick={handleOpen}
                  categoryId={categoryId}
                  hasHotels={true}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      {renderAdminButton()}
    </>
  );
}
