/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  //ConventionPage.js
  newTitle: "New Convention",
  editTitle: "Edit Convention",

  //Conventions.js
  conventionsTitle: "Conventions",

  //Login.js
  buttonText: "AUTHORIZE APP",

  //HotelBar.js
  selectLabel: "Country",
  addButtonText: "Add",
  selectCountryLabel: "Select the country",

  //HotelPage.js
  emptyText: "No country selected",
  policyTitle: "Travel Policy",
  policyButton: "Open the Travel Policy",
  concurDocumentsTitle: "Concur",
  documentsTitle: "Documents",
  insurancePoliciesTitle: "Insurance Policies",
  noDocumentsText: "No document found",
  trmCourseTitle: "ISCRIZIONE CORSO TRM",
  trmCourseText: [
    "Per iscriverti al corso TRM, ti chiediamo gentilmente di scrivere una mail al seguente indirizzo: ",
    "trmlearning.external@valentino.com ",
    "compilando ed inviando il file che trovi a questo",
    " link.",
    "Una volta fatto ti chiediamo di seguire le indicazioni che riceverai via mail per poter accedere.",
  ],

  //SearchInput.js
  locationError: "Location not enabled",
  searchLabel: "Search",
  searchEmpty: "No options",

  //UserModal.js
  informationText: "Information",
  contactsText: "Contacts",
  addressText: "Address",
  notesText: "Notes",
  mapsText: "Open in Google Maps",

  //NewDocumentModal.js
  submitValidation: "*Name and File are required.",
  modalButtonText: "Add",
  modalTitle: "Add a document",
  modalInfo:
    "Choose a name and select a file or a link in order to create a document",
  modalInfoRisk:
    "Choose a name and select a file in order to create a document",
  modalInputPlaceholder: "Name",
  modalType: "Type",
  modalDropzoneText: "Drag and drop a file here or click",
  modalBackButtonText: "Cancel",
  modalSubmitButtonText: "Add",

  //Form.js
  formNameLabel: "Name",
  formNamePlaceholder: "Insert a name",
  formDescriptionLabel: "Description",
  formDescriptionPlaceholder: "Insert a description",
  formLocationLabel: "Position",
  formAddressLabel: "Address",
  formAddressPlaceholder: "Insert the address",
  formProvinceLabel: "Province",
  formProvincePlaceholder: "Insert the province",
  formEmailPlaceholder: "Insert the email address",
  formPhoneLabel: "Phone",
  formPhonePlaceholder: "Insert the phone number",
  formWebsiteLabel: "Website",
  formWebsitePlaceholder: "Insert the website",
  formPriceRangeLabel: "Price Range",
  formPriceRangeDescriptionPlaceholder: "Insert description",
  formHasRestaurantLabel: "With Restaurant",
  formBookingOptionLabel: "Booking Option",
  formNotesLabel: "Notes",
  formNotesPlaceholder: "Insert some notes",
  formStatusLabel: "Status",
  formStatusApproved: "Approved",
  formStatusDraft: "Draft",
  formExpirationLabel: "Expiration",
  formDocumentsLabel: "Documents",
  formDeleteButtonText: "DELETE",
  formAddButtonText: "Add",
  formUpdateButtonText: "Update",
  formUploadedFilesLabel: "Uploaded Files",

  //CTableHead.js
  tableNameLabel: "Name",
  tableStatusLabel: "Status",
  tableProvinceLabel: "Province",
  tablePositionLabel: "Position",
  tableRatingLabel: "Total Rating",
  tableUserRatingLabel: "User Rating",
  tableExpirationLabel: "Expiration",
  tableNotesLabel: "Notes",
  tablePriceRangeLabel: "Price",
  tableHasRestaurantLabel: "Restaurant",
  tableHasRestaurantAvailable: "Available",
  tableHasRestaurantUnavailable: "Unavailable",
  tableHasRestaurantUnknown: "",
  tableBookingOptionLabel: "Booking Option",
  tableBookingConcurLabel: "Concur",
  tableBookingEmailLabel: "Email",

  //CTable.js
  tableExpiringText: "EXPIRING",
  tableDraftText: "DRAFT",
  tableApprovedText: "APPROVED",
  tableExpiredText: "EXPIRED",
  tableUserRatingText: "Insert your rating",
  tableExpiringDays: "Expiring in {{days}} days",
  tableExpirationDays: "Expired {{days}} days ago",

  //DeleteModal.js
  deleteModalTitle: "Delete the document?",
  deleteModalInfo: "Are you sure that you want to delete this document?",
  deleteModalBackButtonText: "Cancel",
  deleteModalSubmitButtonText: "Delete",
};
