import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';

import useStyles from './Modal.style';

export default function Modal({children, title, open, onClose}) {
  const classes = useStyles();
  const category = useSelector(state => state.categories.item);

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle className={classes.title}>
        <Grid container>
          {
            category.icon
            ? 
              <Grid item className={classes.titleElement}>
                <Icon style={{color: category.color}}>
                    {category.icon}
                </Icon>
              </Grid>
            : null
          }
          <Grid item className={clsx(classes.titleElement, classes.title)}>
              <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs className={classes.close}>
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func
}

Modal.defaultProps = {
    open: false,
    onClose: () => {}
}