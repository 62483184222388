export const listCategories = `query ListCategories($filter: ModelCategoryFilterInput) {
  listCategories(filter: $filter, limit: 1000) {
    items {
      id
      name
      nameLocalized {
        it
        en
      }
      description
      descriptionLocalized {
        it
        en
      }
      icon
      color
    }
  }
}`;

export const listCategoriesForHotels = `query ListCategories($filter: ModelCategoryFilterInput) {
  listCategories(filter: $filter, limit: 1000) {
    items {
      id
      name
      nameLocalized {
        en
        it
      }
      description
      icon
      color
    }
  }
}`;

export const listDocuments = `query GetMacrocategory($id: ID!) {
  getMacrocategory(id: $id) {
    categories {
      items {
        documents {
          items {
            id
            name
            description
            location
            address
            province
            email
            phone
            website
            note
            state
            expireOn
            rating
            voteCasts
            properties {
              bookingOption
              hasRestaurant
              priceRange {
                value
                currency
                description
              }
            }
            ratings {
              items {
                documentId
                userId
                score
              }
            }
          }
        }
      }
    }
  }
}`;

export const listDocumentsByCategory = `query ListDocumentsByCategory($documentCategoryId: ID!) {
  listDocumentsByCategory(documentCategoryId: $documentCategoryId, limit: 1000) {
    items {
      id
      name
      createdAt
      description
      location
      address
      province
      email
      phone
      website
      note
      state
      expireOn
      rating
      voteCasts
      properties {
        bookingOption
        hasRestaurant
        priceRange {
          value
          currency
          description
        }
      }
      ratings {
        items {
          documentId
          userId
          score
        }
      }
      files {
        items {
          id
          title
          s3File {
            s3PresignedUrl
          }
          createdAt
        }
      }
    }
  }
}`;

export const getCategory = `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    nameLocalized {
      it
      en
    }
    icon
    color
  }
}`;

export const getDocument = `
  query GetDocument(
    $id: ID!
  ) {
    getDocument(id: $id) {
      id
      name
      description
      location
      address
      province
      email
      phone
      website
      note
      state
      expireOn
      rating
      voteCasts
      properties {
        bookingOption
        hasRestaurant
        priceRange {
          value
          currency
          description
        }
      }
      ratings {
        items {
          documentId
          userId
          score
        }
      }
      files {
        items {
          id
          title
          s3File {
            s3PresignedUrl
          }
          createdAt
        }
      }
    }
  }
`;

export const searchByWordsAndDistance = `query SearchByWordsAndDistance(
  $filter: SearchableDocumentByWordsAndDistanceFilterInput!
) {
  searchByWordsAndDistance(
    limit: 1000,
    filter: $filter
  )
  {
    items {
      id
      name
      description
      location
      address
      province
      email
      phone
      website
      note
      state
      expireOn
      rating
      voteCasts
      properties {
        bookingOption
        hasRestaurant
        priceRange {
          value
          currency
          description
        }
      }
      ratings {
        items {
          documentId
          userId
          score
        }
      }
    }
    total
    offset
  }
}
`;

export const listLinksByCategory = `query ListLinksByCategory($linkCategoryId: ID!) {
  listLinksByCategory(linkCategoryId: $linkCategoryId, limit: 1000) {
    items {
      id
      name
      type
      url
      createdAt
    }
  }
}`;

export const listRiskCategories = `query ListCategories($filter: ModelCategoryFilterInput) {
  listCategories(filter: $filter, limit: 1000) {
    items {
      id
      name
      nameLocalized {
        en
        it
      }
    }
  }
}`;

export const listRiskDocumentsByCategory = `query ListDocumentsByCategory($documentCategoryId: ID!) {
listDocumentsByCategory(documentCategoryId: $documentCategoryId, limit: 1000) {
  items {
    id
    name
    createdAt
    files {
      items {
        id
        title
        s3File {
          s3PresignedUrl
        }
      }
    }
  }
}
}`;

export const listRiskDocuments = `query GetMacrocategory($id: ID!) {
getMacrocategory(id: $id) {
  categories {
    items {
      documents {
        items {
          id
          name
          createdAt
          files {
            items {
              id
              title
              s3File {
                s3PresignedUrl
              }
            }
          }
        }
      }
    }
  }
}
}`;
