import React, { useState } from "react";
import PropTypes from "prop-types";

export default function DocumentsBox({
  categories,
  items,
  onChangeCategory,
  onClick,
}) {
  const [currentCategory, setCurrentCategory] = useState(
    categories.length && categories[0].id
  );

  const handleChangeCategory = (category) => {
    setCurrentCategory(category.id);
    onChangeCategory(category);
  };

  const sortItemsByName = (a, b) => {
    if( a.name < b.name ) { return -1; }
    if( a.name > b.name ) { return 1; }
    return 0;
  }

  return (
    <div
      className="p-8 overflow-scroll"
      style={{ maxHeight: 500, minHeight: 128 }}
    >
      <div className="md:flex h-full">
        {categories.length > 1 && (
          <div className="w-full md:w-1/6 mr-12">
            <ul className="font-normal text-gray-500 border-b pb-4 md:pb-0 md:border-b-0 truncate cursor-pointer">
              {categories.map((item, index) =>
                currentCategory === item.id ? (
                  <li
                    key={`category-${index}`}
                    className="inline mr-2 md:block md:mr-0 md:mb-2 font-semibold text-gray-800 underline"
                    onClick={() => handleChangeCategory(item)}
                  >
                    {navigator.language !== "it-IT"
                      ? item.nameLocalized.en
                      : item.nameLocalized.it}
                  </li>
                ) : (
                  <li
                    key={`category-${index}`}
                    className="inline mr-2 md:block md:mr-0 md:mb-2"
                    onClick={() => handleChangeCategory(item)}
                  >
                    {navigator.language !== "it-IT"
                      ? item.nameLocalized.en
                      : item.nameLocalized.it}
                  </li>
                )
              )}
            </ul>
          </div>
        )}
        <div
          className={`w-full ${
            categories.length !== 0 && "md:w-5/6 mt-4 md:mt-0"
          }`}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {items.sort(sortItemsByName).map((item, index) => (
              <div
                key={`document-${index}`}
                className="flex h-16 rounded shadow-md overflow-hidden ring-1 ring-gray-200 ring-inset cursor-pointer hover:bg-gray-100 hover:ring-gray-300"
                onClick={() => onClick(item)}
              >
                {item.type && item.type === "VIDEO" ? (
                  <div className="w-16 h-full bg-gradient-to-bl from-red-600 to-red-900">
                    <div className="flex justify-center items-center h-full w-16">
                      <svg
                        enableBackground="new 0 0 512 512"
                        height="32"
                        viewBox="0 0 512 512"
                        width="32"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#fff"
                      >
                        <g>
                          <path d="m338.95 243.28-120-75c-4.625-2.89-10.453-3.043-15.222-.4-4.77 2.643-7.729 7.667-7.729 13.12v150c0 5.453 2.959 10.476 7.729 13.12 2.266 1.256 4.77 1.88 7.271 1.88 2.763 0 5.522-.763 7.95-2.28l120-75c4.386-2.741 7.05-7.548 7.05-12.72s-2.663-9.979-7.049-12.72zm-112.95 60.656v-95.873l76.698 47.937z" />
                          <path d="m437 61h-362c-41.355 0-75 33.645-75 75v240c0 41.355 33.645 75 75 75h362c41.355 0 75-33.645 75-75v-240c0-41.355-33.645-75-75-75zm45 315c0 24.813-20.187 45-45 45h-362c-24.813 0-45-20.187-45-45v-240c0-24.813 20.187-45 45-45h362c24.813 0 45 20.187 45 45z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                ) : (
                  <div className="w-16 h-full bg-gradient-to-bl from-green-600 to-green-900">
                    <div className="flex justify-center items-center h-full w-16">
                      <svg
                        enableBackground="new 0 0 512 512"
                        height="32"
                        viewBox="0 0 512 512"
                        width="32"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#fff"
                      >
                        <g>
                          <path d="m446.605 124.392-119.997-119.997c-2.801-2.802-6.624-4.395-10.608-4.395h-210c-24.813 0-45 20.187-45 45v422c0 24.813 20.187 45 45 45h300c24.813 0 45-20.187 45-45v-332c0-4.09-1.717-7.931-4.395-10.608zm-115.605-73.179 68.787 68.787h-53.787c-8.271 0-15-6.729-15-15zm75 430.787h-300c-8.271 0-15-6.729-15-15v-422c0-8.271 6.729-15 15-15h195v75c0 24.813 20.187 45 45 45h75v317c0 8.271-6.729 15-15 15z" />
                          <path d="m346 212h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                          <path d="m346 272h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                          <path d="m346 332h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                          <path d="m286 392h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                        </g>
                      </svg>
                    </div>
                  </div>
                )}
                <div className="flex items-center px-2 text-xs font-bold">
                  <p className="break-word">{item.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

DocumentsBox.propTypes = {
  categories: PropTypes.array,
  items: PropTypes.array,
  onChangeCategory: PropTypes.func,
  onClick: PropTypes.func,
};

DocumentsBox.defaultProps = {
  categories: [],
  items: [],
  onChangeCategory: () => {},
  onClick: () => {},
};
