import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import i18n from "i18n-js";

import { useDispatch, useSelector } from "react-redux";
import { listCategories } from "../../models/actions/categoryActions";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";

import Can from "../../config/Can";

import SearchInput from "../SearchInput";
import useStyles from "./HotelBar.style";

export default function HotelBar({ categoryId, onAddClick, onChange }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.categories.items);
  const [value, setValue] = useState("");

  const fetchData = useCallback(() => {
    const isHotels = true;
    dispatch(listCategories(isHotels));
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSelect = (event) => {
    const categoryId = event.target.value;

    onChange(categoryId);
    setValue(categoryId);
  };

  const sortCategory = (a, b) => {
    let lang = "it";
    if ( navigator.language !== "it-IT" ) {
      lang = "en";
    }
    if( a.nameLocalized[lang] < b.nameLocalized[lang] ) { return -1; }
    if( a.nameLocalized[lang] > b.nameLocalized[lang] ) { return 1; }
    return 0;
  }

  return (
    <>
      <div className="flex items-center justify-center my-8">
        <div className="border-b border-gray-400 w-1/2"></div>
        <div className="flex items-center px-4">
          <p className="min-w-max text-xs sm:text-sm font-bold leading-none mr-4">
            {i18n.t("selectCountryLabel")}
          </p>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>{i18n.t("selectLabel")}</InputLabel>
            <Select
              value={value}
              onChange={handleSelect}
              label={i18n.t("selectLabel")}
            >
              {categories.sort( sortCategory ).map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {navigator.language !== "it-IT" ? (item.nameLocalized.en || item.name) : item.name}
              </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="border-b border-gray-400 w-1/2"></div>
      </div>

      <div className="flex flex-column mt-4 items-center">
        <div className="flex flex-column items-center">
          <Typography variant="h5">Hotels</Typography>
        </div>
        <div className="ml-4">
          <Can I="view" a="DocumentAddButton">
            <Button variant="outlined" color="primary" onClick={onAddClick}>
              {i18n.t("addButtonText")}
            </Button>
          </Can>
        </div>
        <div className="flex-1 flex justify-end items-center">
          <SearchInput categoryId={categoryId} />
        </div>
      </div>
    </>
  );
}

HotelBar.propTypes = {
  categoryId: PropTypes.string,
  onAddClick: PropTypes.func,
  onChange: PropTypes.func,
};

HotelBar.defaultProps = {
  categoryId: null,
  onAddClick: () => {},
  onChange: () => {},
};
