import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    conventions: {
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        display: 'flex',
        maxWidth: 915, //Communication Width + Communication Margin
        minWidth: 0,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3)
        }
    },
    title: {
        paddingBottom: theme.spacing(3)
    },
    conventionContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    sectionContainer: {
        display: 'flex',
        width: 305 //Communication Width
    }
}));

export default useStyles;