import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RichTextEditor from 'react-rte';

import useStyles from './RichTextField.style';

export default function RichTextField({name, value, placeholder, onChange}) {
    const [isInitialized, setIsInitialized] = useState(false);
    const [internalValue, setInternalValue] = useState(
      RichTextEditor.createEmptyValue()
    );
    const classes = useStyles();

    useEffect(() => {
      if(value.length && !isInitialized) {
        setInternalValue(
          RichTextEditor.createValueFromString(value, 'markdown')
        );
        setIsInitialized(true);
      }
    }, [value, isInitialized])

    useEffect(() => {
      let markdown = internalValue.toString('markdown');
      if(markdown.length === 2 && markdown.charCodeAt(0) === 8203 && markdown.charCodeAt(1) === 10) {
        markdown = ''
      }
      onChange(name, markdown);
    }, [internalValue, onChange, name])

    const toolbarConfig = {
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
          {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
          {label: 'Italic', style: 'ITALIC'}
        ],
        BLOCK_TYPE_BUTTONS: [
          {label: 'UL', style: 'unordered-list-item'},
          {label: 'OL', style: 'ordered-list-item'}
        ]
    };

    const handleChange = (val) => {
      if(!isInitialized) {
        setIsInitialized(true)
      }
      setInternalValue(val);
    }
    
    return (
        <RichTextEditor
            name={name}
            className={classes.root}
            toolbarConfig={toolbarConfig}
            placeholder={placeholder}
            value={internalValue}
            onChange={(val) => handleChange(val)}
        />
    );
}

RichTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

RichTextEditor.defaultProps = {
  placeholder: "",
  onChange: () => {}
}