import React, { useReducer } from 'react';

import AppContext from '../AppContext';
import { authReducer, authInitialState } from '../reducers/authReducer';
import { documentReducer, documentInitialState } from '../reducers/documentReducer';

export default function AppProvider({children}) {

    const [authState, authDispatch] = useReducer(authReducer, authInitialState);
    const [documentState, documentDispatch] = useReducer(documentReducer, documentInitialState);

    return (
        <AppContext.Provider
            value={
                {   
                    authState,
                    authDispatch,
                    documentState,
                    documentDispatch
                }
            }
        >
            {children}
        </AppContext.Provider>
    );
}