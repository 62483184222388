import { combineReducers } from 'redux';
import authReducer from './authReducer';
import categoryReducer from './categoryReducer';
import documentReducer from './documentReducer';
import fileReducer from './fileReducer';

const appReducer = combineReducers({
    auth: authReducer,
    categories: categoryReducer,
    documents: documentReducer,
    files: fileReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
      state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;