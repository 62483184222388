import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 99,
  },
  error: {
    color: "#f44336",
  },
}));

export default useStyles;
