import React, { useEffect, useCallback, useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Hub } from "@aws-amplify/core";

import setupLogRocketReact from 'logrocket-react';

import AppContext from "../models/contexts/AppContext";

import { checkSession } from "../models/actions/authActions";

const LogRocket = require('logrocket/setup')({
  sdkServer: 'https://rock.valentino.ws',
  ingestServer: 'https://rock.valentino.ws'
});

export default function PrivateRoute({ children, ...rest }) {
  const { authDispatch } = useContext(AppContext);

  const authenticated = useSelector((state) => state.auth.authenticated);
  const session = useSelector((state) => state.auth.session);

  const dispatch = useDispatch();

  const checkAuthentication = useCallback(() => {
    dispatch(checkSession());
  }, [dispatch]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  useEffect(() => {
    authDispatch({ type: "check", session });
  }, [session, authDispatch]);

  Hub.listen("auth", () => {
    checkAuthentication();
  });

  const redirect = (location) => {
    switch (authenticated) {
      case true:
        //Init LogRocket
        LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT);
        setupLogRocketReact(LogRocket);

        const { sub, email, name } = session.idToken.payload;

        //Add user data to LogRocket
        LogRocket.identify(sub, {
          name,
          email,
        });

        if (location.pathname === "/login") {
          return <Redirect to={{ pathname: "/" }} />;
        }
        return children;
      case false:
        if (location.pathname !== "/login") {
          return <Redirect to={{ pathname: "/login" }} />;
        }
        return children;
      default:
        return null;
    }
  };

  return <Route {...rest} render={({ location }) => redirect(location)} />;
}
