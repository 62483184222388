import {
  SEARCH_DOCUMENTS_SUCCESS,
  FILTER_DOCUMENTS,
  SEARCH_DOCUMENTS_UPDATE,
  LIST_DOCUMENTS_SUCCESS,
  LIST_CONCUR_DOCUMENTS_SUCCESS,
  LIST_DOCANDSHARE_DOCUMENTS_SUCCESS,
  GET_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  RESET_DOCUMENT,
  RESET_DOCUMENTS,
  RESET_CONCUR_DOCUMENTS,
  RESET_DOCANDSHARE_DOCUMENTS,
  RESET_DOCUMENTS_SEARCH_RESULTS,
} from "../actions/types";

const initialState = {
  concurDocuments: [],
  concurDocument: {},
  docandshareDocuments: [],
  docandshareDocument: {},
  items: [],
  item: {},
  searchResults: [],
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  switch (action.type) {
    case SEARCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        searchResults: action.payload,
      };
    case FILTER_DOCUMENTS:
      return {
        ...state,
        items: state.searchResults.filter(
          (document) => document.name === action.name
        ),
      };
    case SEARCH_DOCUMENTS_UPDATE:
      return {
        ...state,
        items: state.searchResults,
      };
    case LIST_DOCUMENTS_SUCCESS:
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };
    case LIST_CONCUR_DOCUMENTS_SUCCESS:
      return {
        ...state,
        concurDocuments: [...state.concurDocuments, ...action.payload],
      };
    case LIST_DOCANDSHARE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        docandshareDocuments: [...state.docandshareDocuments, ...action.payload],
      };
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        item: action.payload,
      };
    case CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        item: action.payload,
        items: [action.payload, ...state.items],
      };
    case UPDATE_DOCUMENT_SUCCESS:
      var updatedItems = state.items;
      const updatedIndex = updatedItems.findIndex(
        (e) => e.id === action.payload.id
      );
      updatedItems[updatedIndex] = action.payload;
      return {
        ...state,
        item: action.payload,
        items: [...updatedItems],
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        item: initialState.item,
        items: state.items.filter((e) => e.id !== action.payload.id),
      };
    case RESET_DOCUMENT:
      return {
        ...state,
        item: initialState.item,
      };
    case RESET_DOCUMENTS:
      return {
        ...state,
        items: initialState.items,
        item: initialState.item,
      };
    case RESET_CONCUR_DOCUMENTS:
      return {
        ...state,
        concurDocuments: initialState.concurDocuments,
        concurDocument: initialState.concurDocument,
      };
    case RESET_DOCANDSHARE_DOCUMENTS:
      return {
        ...state,
        docandshareDocuments: initialState.docandshareDocuments,
        docandshareDocument: initialState.docandshareDocument,
      };
    case RESET_DOCUMENTS_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: initialState.searchResults,
      };
    default:
      return state;
  }
}
