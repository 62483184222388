import React from 'react';
import Auth from "@aws-amplify/auth";
import i18n from 'i18n-js';

import Button from '@material-ui/core/Button';

export default function LoginPage() {  
    return (
        <div style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => await Auth.federatedSignIn({customProvider: 'MicrosoftAD'})}
                >
                    {i18n.t("buttonText")}
                </Button>
            </div>
        </div>
    );
}