import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import AppContext from "../../../../models/contexts/AppContext";

import {
  listRiskDocuments,
  deleteRiskDocument,
} from "../../../../models/actions/documentActions";

import i18n from "i18n-js";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import PolicyCard from "./PolicyCard";
import useStyles from "./Policies.style";

export default function Policies({ categoryId }) {
  const classes = useStyles();

  const { documentState, documentDispatch } = useContext(AppContext);
  const group = useSelector((state) => state.auth.group);

  const documents = documentState.items;

  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    var result;
    if (categoryId) {
      result = await listRiskDocuments(categoryId);
    } else {
      result = await listRiskDocuments();
    }
    documentDispatch({ type: "list", items: result });
    setLoading(false);
  }, [categoryId, documentDispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData, categoryId]);

  const handleDelete = async (documentId) => {
    await deleteRiskDocument(documentId);
    fetchData();
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Grid container spacing={2} justify="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }

    if (!documents || !documents.length) {
      if (!categoryId) {
        return (
          <Typography variant="h6" style={{ color: "#999" }}>
            {i18n.t("emptyText")}
          </Typography>
        );
      } else {
        return (
          <Typography variant="h6" style={{ color: "#999" }}>
            {i18n.t("noDocumentsText")}
          </Typography>
        );
      }
    }

    return (
      <Grid container spacing={2}>
        {documents.map((item, index) => (
          <Grid key={`policy-${index}`} item xs={12} sm={6} md={3}>
            <PolicyCard
              title={item.name}
              createdAt={new Date(item.createdAt).toLocaleDateString()}
              url={item.files.items[0].s3File.s3PresignedUrl}
              onDelete={group === "Admin" ? () => handleDelete(item.id) : null}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <div className={classes.container}>
      <Grid item xs={12} className={classes.policies}>
        <Grid
          container
          className={classes.container}
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12}>
            {renderContent()}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

Policies.propTypes = {
  categoryId: PropTypes.string.isRequired,
};
