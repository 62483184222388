import React, { useEffect } from 'react';

import useStyles from './Map.style';

const L = window.L;

L.mapbox.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export default function Map({name, addressFieldName, value, onChange}) {
  const classes = useStyles();
  var mapContainer = null;

  useEffect(() => {
    const coords = value.length ? value.split(',') : [0, 0];
    const lat = parseFloat(coords[0]);
    const lng = parseFloat(coords[1]);

    const Marker = L.marker([lat, lng]);

    const GeocoderControl = L.mapbox.geocoderControl('mapbox.places', {
      autocomplete: true
    });

    GeocoderControl.on("select", (data) => {
      const { center, place_name } = data.feature;

      Marker.setLatLng([center[1], center[0]]).update();

      onChange(name, {
        latitude: center[1],
        longitude: center[0]
      });

      onChange(addressFieldName, place_name);
    });


    const Map = L.mapbox.map(mapContainer)
    .setView([lat, lng], 20)
    .addLayer(L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'))
    .addControl(GeocoderControl);

    Marker.addTo(Map);
  }, []);

  return <div ref={e => mapContainer = e} className={classes.position} />;

}