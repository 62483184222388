export const authInitialState = {
    authenticated: null,
    group: "",
    userMode: true
};

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'check':
            //Error
            if(!action.session) {
                return { ...state, authenticated: false };
            }

            const claims = action.session.idToken.payload;
            const group = claims["cognito:groups"][0];

            return {
                ...state,
                authenticated: true,
                group
            };
        case 'toggleUserMode':
            return {
                ...state,
                userMode: !state.userMode
            }
        default:
            throw new Error('Unexpected action');
    }
}