import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";

import { API, graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../../../models/graphql/queries";

import i18n from "i18n-js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import useStyles from "./Countries.style";

export default function Countries({ onChange }) {
  const classes = useStyles();

  const [categories, setCategories] = useState([]);
  const [value, setValue] = useState("");

  const listCategories = useCallback(async () => {
    try {
      const query = await API.graphql(
        graphqlOperation(queries.listRiskCategories, {
          filter: {
            macrocategoryId: {
              eq: process.env.REACT_APP_RISKS_MACROCATEGORY_ID,
            },
          },
        })
      );
      setCategories(query.data.listCategories.items);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    listCategories();
  }, [listCategories]);

  const handleSelect = (event) => {
    const categoryId = event.target.value;

    onChange(categoryId);
    setValue(categoryId);
  };

  const sortCategory = (a, b) => {
    let lang = "it";
    if ( navigator.language !== "it-IT" ) {
      lang = "en";
    }
    if( a.nameLocalized[lang] < b.nameLocalized[lang] ) { return -1; }
    if( a.nameLocalized[lang] > b.nameLocalized[lang] ) { return 1; }
    return 0;
  }

  return (
    <div className="flex items-center">
      <p className="min-w-max text-xs sm:text-sm font-bold leading-none mr-4">
        {i18n.t("selectCountryLabel")}
      </p>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{i18n.t("selectLabel")}</InputLabel>
        <Select
          value={value}
          onChange={handleSelect}
          label={i18n.t("selectLabel")}
        >
          {categories.sort( sortCategory ).map((item, index) => (
            <MenuItem value={item.id} key={index}>
              {navigator.language !== "it-IT" ? (item.nameLocalized.en || item.name) : item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

Countries.propTypes = {
  onChange: PropTypes.func,
};

Countries.defaultProps = {
  onChange: () => {},
};
