import Auth from '@aws-amplify/auth';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    Auth: {
        mandatorySignIn: true,
        region: process.env.REACT_APP_COGNITO_REGION,
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
            redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
            redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
            responseType: 'code'
        }
    },
    Storage: {
        AWSS3: {
            bucket: 'corporate-docs-storage172114-dev',
            region: 'eu-west-1'
        }
    },
    graphql_headers: async () => {
        const session = await Auth.currentSession();
        return {
            Authorization: session.getIdToken().getJwtToken()
        }
    },
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_URI,
    aws_appsync_region: process.env.REACT_APP_COGNITO_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
};