import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';
import Grid from '@material-ui/core/Grid';

import { getDocument } from '../../models/actions/documentActions';
import { toggleUsermode } from '../../models/actions/authActions';
import { resetCategory } from '../../models/actions/categoryActions';

import Can from '../../config/Can';
import TitleBar from '../../components/TitleBar/TitleBar';
import CTableContainer from './components/CTableContainer';
import useStyles from './ConventionPage.style';
import Modal from '../../components/Modal/Modal';
import UserModal from '../../components/UserModal/UserModal';
import Form from '../../components/Form/Form';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';

export default function ConventionPage() {
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ isNew, setIsNew ] = useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    const category = useSelector(state => state.categories.item);
    const userMode = useSelector(state => state.auth.userMode);

    useEffect(() => {
        dispatch( resetCategory() );
    }, [dispatch])

    const handleOpen = (id) => {
        dispatch( getDocument(id) );
        setIsNew(false);
        setModalOpen(true);
    }

    const handleOpenNew = () => {
        setModalOpen(true);
    }

    const handleClose = () => {
        setModalOpen(false);
        setIsNew(true);
    }

    const renderTitle = () => {
        if(isNew) {
            return i18n.t('newTitle');
        }
        return i18n.t('editTitle');
    }

    const renderAdminButton = () => {
        if(!category.id) {
            return null;
        }

        const style =
            userMode
            ? { backgroundColor: category.color, color: 'white' }
            : {};

        return (
            <Can I="view" a="AdminButton">
                <Fab
                    style={style}
                    color={userMode ? "inherit" : "primary"}
                    className={classes.fab}
                    onClick={() => dispatch( toggleUsermode() )}
                >
                    <Icon>{userMode ? "edit" : "visibility"}</Icon>
                </Fab>
            </Can>
        )
    }

    return (
        <>
            <div className={classes.conventionPage}>
                <Can I="view" a="AdminModal">
                    <Modal
                        title={renderTitle()}
                        open={modalOpen}
                        onClose={handleClose}
                    >
                        <Form isNew={isNew} onSubmit={handleClose}/>
                    </Modal>
                </Can>
                <Can I="view" a="UserModal">
                    <UserModal
                        title={"USERMODAL"}
                        open={modalOpen}
                        onClose={handleClose}
                    />
                </Can>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TitleBar onAddClick={handleOpenNew}/>
                    </Grid>
                    <Grid item xs={12}>
                        <CTableContainer onNameClick={handleOpen}/>
                    </Grid>
                </Grid>
            </div>
            {renderAdminButton()}
        </>
    );
}