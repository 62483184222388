import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import i18n from "i18n-js";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Formik } from "formik";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import Slider from "@material-ui/core/Slider";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  createDocument,
  updateDocument,
  deleteDocument,
  resetDocument,
} from "../../models/actions/documentActions";

import { uploadFile, deleteFile } from "../../models/actions/fileActions";

import RichTextField from "./components/RichTextField/RichTextField";
import Map from "./components/Map/Map";
import Upload from "./components/Upload/Upload";
import FormSkeleton from "./components/FormSkeleton/FormSkeleton";
import useStyles from "./Form.style";

export default function Form({ isNew, onSubmit, categoryId, isHotel }) {
  var formInitialValues = {
    name: "",
    description: "",
    location: "",
    address: "",
    note: "",
    expireOn: new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ).toISOString(),
    state: false,
  };

  //IS HOTEL
  if (isHotel) {
    formInitialValues.hasRestaurant = false;
    formInitialValues.priceRange = [50, 100];
    formInitialValues.priceRangeCurrency = "EUR";
    formInitialValues.bookingOption = "EMAIL";
  }

  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();
  const document = useSelector((state) => state.documents.item);

  var { id } = useParams();

  if (!id) {
    id = categoryId;
  }

  const classes = useStyles();

  const CURRENCIES = ["AUD", "CAD", "EAD", "EUR", "GBP", "USD"];
  const RESTAURANT_STATES = [
    {
      id: false,
      label: i18n.t("tableHasRestaurantUnavailable")
    },
    {
      id: true,
      label: i18n.t("tableHasRestaurantAvailable")
    },
    {
      id: "unknown",
      label: i18n.t("tableHasRestaurantUnknown")
    }
  ];

  const BOOKING_OPTIONS = [
    {
      id: "CONCUR",
      label: i18n.t("tableBookingConcurLabel")
    },
    {
      id: "EMAIL",
      label: i18n.t("tableBookingEmailLabel")
    }
  ];

  const handleUpload = useCallback(async () => {
    if (files) {
      for (let i = 0; i < files.length; i++) {
        dispatch(uploadFile(document.id, files[i]));
      }
    }
    onSubmit();
  }, [files, document, onSubmit, dispatch]);

  const handleDelete = async () => {
    dispatch(deleteDocument({ id: document.id }));
    onSubmit();
  };

  const handleExpireDateChange = async (date, setFieldValue) => {
    setFieldValue("expireOn", date.toISOString());
  };

  const initializeDocumentState = useCallback(() => {
    let state;
    if (document.id) {
      if (document.state !== "APPROVED") {
        state = false;
      } else {
        state = true;
      }
    } else {
      state = false;
    }
    return state;
  }, [document]);

  const initializeDocumentExpireOn = useCallback(() => {
    let date;
    if (document.id) {
      date = document.expireOn;
    } else {
      date = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ).toISOString();
    }
    return date;
  }, [document]);

  useEffect(() => {
    dispatch(resetDocument());
    if (isNew) {
      setLoading(false);
    }
  }, [dispatch, isNew]);

  useEffect(() => {
    if (document.id) {
      var initialValues = {
        name: document.name || "",
        description: document.description || "",
        location: document.location || "",
        address: document.address || "",
        province: document.province || "",
        email: document.email || "",
        phone: document.phone || "",
        website: document.website || "",
        note: document.note || "",
        expireOn: initializeDocumentExpireOn(),
        state: initializeDocumentState(),
      };
      
      if (isHotel) {
        initialValues.hasRestaurant = document.properties.hasRestaurant === null ? "unknown" : document.properties.hasRestaurant;
        initialValues.priceRange = document.properties.priceRange.value || [50, 100];
        initialValues.priceRangeCurrency = document.properties.priceRange.currency || "EUR";
        initialValues.priceRangeDescription = document.properties.priceRange.description || "";
        initialValues.bookingOption = document.properties.bookingOption || "EMAIL";
      }

      setInitialValues(initialValues);
      setLoading(false);
    }
    if (document.id && isUploading) {
      setIsUploading(false);
      handleUpload();
    }
  }, [document, isHotel, isUploading, handleUpload, initializeDocumentExpireOn, initializeDocumentState]);

  const ref = useRef(null);

  const renderMap = (values, setFieldValue) => {
    const onChange = (field, value) => {
      if (field === "address") {
        if (!ref.current.values.address) {
          setFieldValue(field, value);
        }
      } else {
        setFieldValue(field, value);
      }
    };

    const map = (
      <Map
        name="location"
        addressFieldName="address"
        value={values.location}
        onChange={onChange}
      />
    );

    if (isNew) {
      return map;
    }
    if (!document.id) {
      return null;
    }
    return map;
  };

  const renderAssociatedFiles = () => {
    if (!document.id) {
      return null;
    }
    if (!document.files.items.length) {
      return null;
    }

    document.files.items.sort((first, second) => {
      if (first.createdAt < second.createdAt) {
        return -1;
      }
      if (first.createdAt > second.createdAt) {
        return 1;
      }
      return 0;
    });

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              {i18n.t("formUploadedFilesLabel")}
            </InputLabel>
          </Grid>

          <Grid item xs={12}>
            {document.files.items.map((file, index) => (
              <Chip
                key={index}
                className={classes.file}
                variant="outlined"
                color="primary"
                onDelete={() => {
                  dispatch(deleteFile(file.id));
                  onSubmit();
                }}
                deleteIcon={<Icon>delete</Icon>}
                label={
                  <a
                    className={classes.fileLink}
                    href={file.s3File.s3PresignedUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {file.title}
                  </a>
                }
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  if (loading) {
    return <FormSkeleton />;
  }

  return (
    <Formik
      innerRef={ref}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={async (values) => {
        setIsUploading(true);
        let input = { ...values }; //To copy without Ref in Formik

        if (typeof input.location === "string") {
          const coords = input.location.length
            ? input.location.split(",")
            : [0, 0];
          const lat = parseFloat(coords[0]);
          const lng = parseFloat(coords[1]);

          input.location = {
            latitude: lat,
            longitude: lng,
          };
        }

        input.documentCategoryId = id;
        input.state = input.state ? "APPROVED" : "DRAFT";

        if (isHotel) {
          let properties = {
            priceRange: {
              value: values.priceRange,
              currency: values.priceRangeCurrency,
              description: values.priceRangeDescription,
            },
            bookingOption: values.bookingOption
          }
          if (values.hasRestaurant !== "unknown") {
            properties.hasRestaurant = values.hasRestaurant;
          }
          
          input.properties = properties;
        }

        //Remove wrong keys from Form
        delete input.bookingOption;
        delete input.hasRestaurant;
        delete input.priceRange;
        delete input.priceRangeCurrency;
        delete input.priceRangeDescription;
        
        if (isNew) {
          dispatch(createDocument(input));
        } else {
          input.id = document.id;
          dispatch(updateDocument(input));
        }
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formNameLabel")}
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder={i18n.t("formNamePlaceholder")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formDescriptionLabel")}
                  </InputLabel>
                </Grid>

                <Grid item xs={12}>
                  <RichTextField
                    name="description"
                    value={values.description}
                    onChange={setFieldValue}
                    placeholder={i18n.t("formDescriptionPlaceholder")}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formLocationLabel")}
                  </InputLabel>
                </Grid>

                <Grid item xs={12}>
                  {renderMap(values, setFieldValue)}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formAddressLabel")}
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder={i18n.t("formAddressPlaceholder")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formProvinceLabel")}
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="province"
                    value={values.province}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder={i18n.t("formProvincePlaceholder")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>Email</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder={i18n.t("formEmailPlaceholder")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formPhoneLabel")}
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder={i18n.t("formPhonePlaceholder")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formWebsiteLabel")}
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="website"
                    value={values.website}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder={i18n.t("formWebsitePlaceholder")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            {isHotel ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel className={classes.label}>
                      {i18n.t("formPriceRangeLabel")}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="flex flex-row justify-center items-center">
                      <div className="flex-1 mt-10">
                        <Slider
                          id="priceRange"
                          name="priceRange"
                          value={values.priceRange}
                          onChange={(e, value) => {
                            setFieldValue("priceRange", value);
                          }}
                          valueLabelDisplay="on"
                          min={0}
                          max={1000}
                        />
                      </div>
                      <div className="ml-4" style={{ minWidth: 75 }}>
                        <FormControl variant="outlined" className="w-full">
                          <Select
                            name="priceRangeCurrency"
                            value={values.priceRangeCurrency}
                            onChange={handleChange}
                          >
                            {CURRENCIES.map((item, index) => (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="priceRangeDescription"
                      value={values.priceRangeDescription}
                      onChange={handleChange}
                      variant="outlined"
                      placeholder={i18n.t("formPriceRangeDescriptionPlaceholder")}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {isHotel ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel className={classes.label}>
                      {i18n.t("formHasRestaurantLabel")}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" className="w-full">
                      <Select
                        name="hasRestaurant"
                        value={values.hasRestaurant}
                        onChange={handleChange}
                      >
                        {RESTAURANT_STATES.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {isHotel ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel className={classes.label}>
                      {i18n.t("formBookingOptionLabel")}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" className="w-full">
                      <Select
                        name="bookingOption"
                        value={values.bookingOption}
                        onChange={handleChange}
                      >
                        {BOOKING_OPTIONS.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formNotesLabel")}
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="note"
                    value={values.note}
                    onChange={handleChange}
                    variant="outlined"
                    placeholder={i18n.t("formNotesPlaceholder")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formStatusLabel")}
                  </InputLabel>
                </Grid>

                <Grid item xs={12}>
                  <Grid container className={classes.state}>
                    <Grid item xs={6} className={classes.flexItem}>
                      <FormControl component="fieldset">
                        <FormHelperText>
                          {values.state
                            ? i18n.t("formStatusApproved")
                            : i18n.t("formStatusDraft")}
                        </FormHelperText>
                        <FormControlLabel
                          control={
                            <Switch
                              name="state"
                              checked={values.state}
                              onChange={handleChange} //(e) => setState(e.target.checked)
                              color="primary"
                            />
                          }
                        />
                      </FormControl>
                    </Grid>

                    <Divider orientation="vertical" flexItem />

                    <Grid item xs className={classes.flexItem}>
                      <FormControl component="fieldset">
                        <FormHelperText>
                          {i18n.t("formExpirationLabel")}
                        </FormHelperText>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className={classes.datePicker}
                            margin="normal"
                            format="MM/dd/yyyy"
                            name="expireOn"
                            value={values.expireOn}
                            onChange={(date) =>
                              handleExpireDateChange(date, setFieldValue)
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>
                    {i18n.t("formDocumentsLabel")}
                  </InputLabel>
                </Grid>

                <Grid item xs={12}>
                  <Upload onChange={(value) => setFiles(value)} />
                </Grid>
              </Grid>
            </Grid>

            {renderAssociatedFiles()}

            <Grid item xs={12} className={classes.submit}>
              <Button color="primary" disableElevation onClick={handleDelete}>
                {i18n.t("formDeleteButtonText")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disableElevation
              >
                {isNew
                  ? i18n.t("formAddButtonText")
                  : i18n.t("formUpdateButtonText")}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

Form.propTypes = {
  categoryId: PropTypes.string,
  isNew: PropTypes.bool.isRequired,
  isHotel: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  categoryId: null,
  isHotel: false,
  onSubmit: () => { },
};
