import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    label: {
        fontWeight: 700,
        color: theme.palette.primary.main
    },
    state: {
        border: `1px solid ${theme.palette.action.disabled}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary
    },
    flexItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    datePicker: {
        marginTop: theme.spacing(1)
    },
    submit: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    fileLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    file: {
        marginRight: theme.spacing(1)
    }
}));

export default useStyles;