import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titleElement: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        marginLeft: theme.spacing(1)
    },
    close: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    content: {
        height: '100%',
        marginBottom: theme.spacing(2)
    }
}));

export default useStyles;