import React, { useState, useEffect } from 'react';

import useStyles from './UserMap.style';

const L = window.L;

L.mapbox.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export default function UserMap({location}) {
    const classes = useStyles();
    const [ map, setMap ] = useState();
    const [ marker, setMarker ] = useState();

    var mapContainer = null;

    useEffect(() => {
        const coords = location.split(',');
        const lat = parseFloat(coords[0]);
        const lng = parseFloat(coords[1]);

        const Map = L.mapbox.map(mapContainer)
            .setView([lat, lng], 20)
            .addLayer(L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11'))

        const Marker = L.marker([lat, lng]);

        Marker.addTo(Map);

        setMap(Map);
        setMarker(Marker);
    }, []);

    useEffect(() => {
        if(map && marker) {
            const coords = location.split(',');
            const lat = parseFloat(coords[0]);
            const lng = parseFloat(coords[1]);

            map.setView([lat, lng], 20);
            marker.setLatLng([lat, lng]).update();
        }
    }, [map, marker, location]);

    return <div ref={e => mapContainer = e} className={classes.position} />;
}