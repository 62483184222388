import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "@aws-amplify/core";
import amplifyConfig from "./amplifyConfig";
import AppProvider from "./models/contexts/providers/AppProvider";

import { ValentinoProvider } from "@vfg-digital/core";
import store from "./store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

Amplify.configure(amplifyConfig);

ReactDOM.render(
  <Router>
    <ValentinoProvider>
      <AppProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </AppProvider>
    </ValentinoProvider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
