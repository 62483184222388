import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  policies: {
    // paddingBottom: theme.spacing(3),
  },
}));

export default useStyles;
