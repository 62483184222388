import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import ChevronRight from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';

import DeleteModal from './DeleteModal';

export default function PolicyCard({title, createdAt, url, onDelete}) {
    const handleClick = () => {
        window.open(url, "_blank");
    }

    return (
        <Card style={{
            boxShadow:  "20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff"
        }}>
            <div style={{padding: 16}}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant="subtitle1">
                            {title}
                        </Typography>
                        <Typography variant="caption">
                            {createdAt}
                        </Typography>
                    </Grid>
                    <Grid item style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <IconButton size="small" onClick={handleClick}>
                            <ChevronRight />
                        </IconButton>
                        {
                            onDelete ?
                            <DeleteModal onSubmit={onDelete} /> : null
                        }
                    </Grid>
                </Grid>
            </div>
        </Card>
    );
}

PolicyCard.propTypes = {
    title: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    onDelete: PropTypes.func
}

PolicyCard.defaultProps = {
    onDelete: null
}