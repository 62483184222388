import Auth from "@aws-amplify/auth";

import {
  CHECK_SESSION_REQUEST,
  CHECK_SESSION_SUCCESS,
  CHECK_SESSION_FAILURE,
  TOGGLE_USERMODE,
} from "./types";

export const checkSession = () => async (dispatch) => {
  dispatch({ type: CHECK_SESSION_REQUEST });
  try {
    const session = await Auth.currentSession();

    dispatch({
      type: CHECK_SESSION_SUCCESS,
      payload: session,
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: CHECK_SESSION_FAILURE,
      payload: e,
    });
  }
};

export const toggleUsermode = () => async (dispatch) => {
  dispatch({ type: TOGGLE_USERMODE });
};
