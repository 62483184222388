import React from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "./navigation/PrivateRoute";
import LoginPage from "./pages/LoginPage/LoginPage";
import HomePage from "./pages/HomePage/HomePage";
import ConventionPage from "./pages/ConventionPage/ConventionPage";
import HotelPage from "./pages/HotelPage/HotelPage";

export default function Router() {
  const appMode = process.env.REACT_APP_MODE;

  return (
    <Switch>
      <PrivateRoute exact path="/login">
        <LoginPage />
      </PrivateRoute>
      {appMode === "welfare" ? (
        <PrivateRoute exact path="/">
          <HomePage />
        </PrivateRoute>
      ) : (
        <PrivateRoute exact path="/">
          <HotelPage />
        </PrivateRoute>
      )}
      <PrivateRoute exact path="/conventions/:id">
        <ConventionPage />
      </PrivateRoute>
    </Switch>
  );
}
