import React from 'react';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './UserModalSkeleton.style';

export default function UserModalSkeleton() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.fieldContainer}>
                    <Skeleton variant="rect" width="100%" height={80} />
                </Grid>
                <Grid item xs={12} className={classes.fieldContainer}>
                    <Skeleton variant="rect" width="100%" height={80} />
                </Grid>
            </Grid>
        </div>
    );
}