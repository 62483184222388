import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    titleElement: {
        display: 'flex',
        alignItems: 'center'
    },
    titleContainer: {
        paddingLeft: theme.spacing(2),
        color: '#fff'
    },
    title: {
        paddingLeft: theme.spacing(1)
    },
    close: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    content: {
        height: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    files: {
        marginTop: theme.spacing(2)
    },
    fileLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    file: {
        marginRight: theme.spacing(1)
    }
}));

export default useStyles;