import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "@aws-amplify/api";

import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import { getDocument } from "../../../../models/graphql/queries";

export default function VademecumLink() {

    const [link, setLink] = useState(null);

    useEffect(() => {
        const getVademecumLink = async () => {
            try {
                const query = await API.graphql(
                    graphqlOperation( getDocument, {
                        id: "trm-vademecum",
                    } )
                );
                setLink( query.data.getDocument.files.items[0].s3File.s3PresignedUrl );
            } catch (error) {
                console.log(error);
            }
        };
        getVademecumLink();
    }, []);

    return (
        <>
            { link && 
                <div className="flex flex-1 justify-end">
                <a
                  href={link}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noopener noreferrer"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    endIcon={<Icon>open_in_new</Icon>}
                  >
                    Vademecum 
                  </Button>
                </a>
              </div>
            }
        </>
    )

}