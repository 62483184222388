import {
    LIST_CATEGORIES_SUCCESS,
    GET_CATEGORY_SUCCESS,
    RESET_CATEGORY
} from '../actions/types';

const initialState = {
    items: [],
    item: {}
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function(state = initialState, action) {
    switch (action.type) {
        case LIST_CATEGORIES_SUCCESS:
            return {
                ...state,
                items: action.payload
            }
        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                item: action.payload
            }
        case RESET_CATEGORY:
            return {
                ...state,
                item: initialState.item
            }
        default:
            return state;
    }
}