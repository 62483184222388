import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import { API, graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../../../models/graphql/mutations";

import AppContext from "../../../../models/contexts/AppContext";
import {
  createRiskDocument,
  listRiskDocuments,
} from "../../../../models/actions/documentActions";

import { DropzoneArea } from "material-ui-dropzone";

import i18n from "i18n-js";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import useStyles from "./NewModal.style";

export default function NewModal({ categoryId }) {
  const classes = useStyles();

  const { documentDispatch } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [files, setFiles] = useState([]);

  const _createPresignedUrl = async (documentId, fileType, name) => {
    try {
      const mutation = await API.graphql(
        graphqlOperation(mutations.createPresignedUrl, {
          input: { documentId, fileType, name },
        })
      );
      return mutation.data.createPresignedUrl;
    } catch (error) {
      console.log(error);
    }
  };

  const _uploadToS3 = async (file, presignedUrl) => {
    const { name, type } = file;
    try {
      const formData = new FormData();
      formData.append("file", file, name);
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": type },
        body: formData,
      };
      const response = await fetch(presignedUrl, requestOptions);
      return {
        versionId: response.headers.get("x-amz-version-id"),
      };
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFile = async (documentId, file) => {
    const { name, type, size } = file;

    const presignedUrlData = await _createPresignedUrl(documentId, type, name);
    const { presignedUrl, bucket, key } = presignedUrlData;

    const uploadedData = await _uploadToS3(file, presignedUrl);
    const { versionId } = uploadedData;

    const input = {
      documentId,
      title: name,
      language: "it",
      s3File: {
        bucket,
        fileType: type,
        key,
        size,
        versionId,
      },
    };

    try {
      await API.graphql(graphqlOperation(mutations.createFile, { input }));
    } catch (e) {
      console.error(e);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleFilesChange = (selectedFiles) => {
    setFiles(selectedFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "" || !files.length) {
      setError(i18n.t("submitValidation"));
      return;
    }

    setLoading(true);

    var result = await createRiskDocument(name, categoryId);

    for (let i = 0; i < files.length; i++) {
      await uploadFile(result.id, files[i]);
    }

    result = await listRiskDocuments(categoryId);
    documentDispatch({ type: "reset" });
    documentDispatch({ type: "list", items: result });

    setLoading(false);
    setOpen(false);
    setError(null);
  };

  const renderLoading = () => {
    if (!loading) {
      return null;
    }

    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {i18n.t("modalButtonText")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {i18n.t("modalTitle")}
          </DialogTitle>
          <DialogContent>
            {error ? <p className={classes.error}>{error}</p> : null}
            {renderLoading()}
            <DialogContentText>{i18n.t("modalInfoRisk")}</DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="name"
                  label={i18n.t("modalInputPlaceholder")}
                  type="text"
                  fullWidth
                  required={true}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  filesLimit={1}
                  maxFileSize={100000000} //100 Mb
                  acceptedFiles={["image/jpeg", "image/png", "application/pdf"]}
                  dropzoneText={i18n.t("modalDropzoneText")}
                  showFileNames={true}
                  showAlerts={false}
                  required={true}
                  onChange={handleFilesChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {i18n.t("modalBackButtonText")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disableElevation
            >
              {i18n.t("modalSubmitButtonText")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

NewModal.propTypes = {
  categoryId: PropTypes.string.isRequired,
};
