import { Ability, AbilityBuilder } from "@casl/ability";
import store from "../store";

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

// Get current auth state
const ability = new Ability([], { subjectName });

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().auth;
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth));
  }
});

function defineRulesFor(auth) {
  const { can, rules } = new AbilityBuilder(Ability);

  // Renders that we want to restrict
  if (auth.group === "Admin") {
    can("view", "AdminButton");
    if (!auth.userMode) {
      can("view", "AdminModal");
      can("view", "DocumentsTableState");
      can("view", "DocumentAddButton");
    } else {
      can("view", "UserModal");
    }
  } else {
    can("view", "UserModal");
  }
  return rules;
}

export default ability;
