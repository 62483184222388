//ROOT
export const RESET_APP = "RESET_APP";

//AUTH
export const CHECK_SESSION_REQUEST = "CHECK_SESSION_REQUEST";
export const CHECK_SESSION_SUCCESS = "CHECK_SESSION_SUCCESS";
export const CHECK_SESSION_FAILURE = "CHECK_SESSION_FAILURE";
export const TOGGLE_USERMODE = "TOGGLE_USERMODE";

//CATEGORIES
export const LIST_CATEGORIES_REQUEST = "LIST_CATEGORIES_REQUEST";
export const LIST_CATEGORIES_SUCCESS = "LIST_CATEGORIES_SUCCESS";
export const LIST_CATEGORIES_FAILURE = "LIST_CATEGORIES_FAILURE";
export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";
export const RESET_CATEGORY = "RESET_CATEGORY";

//DOCUMENTS
export const SEARCH_DOCUMENTS_REQUEST = "SEARCH_DOCUMENTS_REQUEST";
export const SEARCH_DOCUMENTS_SUCCESS = "SEARCH_DOCUMENTS_SUCCESS";
export const SEARCH_DOCUMENTS_FAILURE = "SEARCH_DOCUMENTS_FAILURE";
export const SEARCH_DOCUMENTS_UPDATE = "SEARCH_DOCUMENTS_UPDATE";
export const FILTER_DOCUMENTS = "FILTER_DOCUMENTS";
export const LIST_DOCUMENTS_REQUEST = "LIST_DOCUMENTS_REQUEST";
export const LIST_DOCUMENTS_SUCCESS = "LIST_DOCUMENTS_SUCCESS";
export const LIST_DOCUMENTS_FAILURE = "LIST_DOCUMENTS_FAILURE";
export const LIST_CONCUR_DOCUMENTS_REQUEST = "LIST_CONCUR_DOCUMENTS_REQUEST";
export const LIST_CONCUR_DOCUMENTS_SUCCESS = "LIST_CONCUR_DOCUMENTS_SUCCESS";
export const LIST_CONCUR_DOCUMENTS_FAILURE = "LIST_CONCUR_DOCUMENTS_FAILURE";
export const RESET_CONCUR_DOCUMENTS = "RESET_CONCUR_DOCUMENTS";
export const LIST_DOCANDSHARE_DOCUMENTS_REQUEST = "LIST_DOCANDSHARE_DOCUMENTS_REQUEST";
export const LIST_DOCANDSHARE_DOCUMENTS_SUCCESS = "LIST_DOCANDSHARE_DOCUMENTS_SUCCESS";
export const LIST_DOCANDSHARE_DOCUMENTS_FAILURE = "LIST_DOCANDSHARE_DOCUMENTS_FAILURE";
export const RESET_DOCANDSHARE_DOCUMENTS = "RESET_DOCANDSHARE_DOCUMENTS";
export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "GET_DOCUMENT_FAILURE";
export const CREATE_DOCUMENT_REQUEST = "CREATE_DOCUMENT_REQUEST";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_FAILURE = "CREATE_DOCUMENT_FAILURE";
export const CREATE_DOCUMENT_RATING_REQUEST = "CREATE_DOCUMENT_RATING_REQUEST";
export const CREATE_DOCUMENT_RATING_SUCCESS = "CREATE_DOCUMENT_RATING_SUCCESS";
export const CREATE_DOCUMENT_RATING_FAILURE = "CREATE_DOCUMENT_RATING_FAILURE";
export const UPDATE_DOCUMENT_REQUEST = "UPDATE_DOCUMENT_REQUEST";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAILURE = "UPDATE_DOCUMENT_FAILURE";
export const UPDATE_DOCUMENT_RATING_REQUEST = "UPDATE_DOCUMENT_RATING_REQUEST";
export const UPDATE_DOCUMENT_RATING_SUCCESS = "UPDATE_DOCUMENT_RATING_SUCCESS";
export const UPDATE_DOCUMENT_RATING_FAILURE = "UPDATE_DOCUMENT_RATING_FAILURE";
export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";
export const RESET_DOCUMENT = "RESET_DOCUMENT";
export const RESET_DOCUMENTS = "RESET_DOCUMENTS";
export const RESET_DOCUMENTS_SEARCH_RESULTS = "RESET_DOCUMENTS_SEARCH_RESULTS";

//FILES
export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";
