import React from 'react';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from './TitleBarSkeleton.style';

export default function TitleBarSkeleton() {
    const classes = useStyles();

    return (
        <>
            <Grid item className={clsx(classes.elementContainer, classes.iconContainer)}>
                <Skeleton variant="circle" width={24} height={24} />
            </Grid>
            <Grid item className={classes.elementContainer}>
                <Skeleton variant="rect" width={160} height={24} />
            </Grid>
        </>
    );
}