import { API, graphqlOperation } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import omitBy from "lodash.omitby";
import isEmpty from "lodash.isempty";

import {
  SEARCH_DOCUMENTS_REQUEST,
  SEARCH_DOCUMENTS_SUCCESS,
  SEARCH_DOCUMENTS_FAILURE,
  SEARCH_DOCUMENTS_UPDATE,
  FILTER_DOCUMENTS,
  LIST_DOCUMENTS_REQUEST,
  LIST_DOCUMENTS_SUCCESS,
  LIST_DOCUMENTS_FAILURE,
  LIST_CONCUR_DOCUMENTS_REQUEST,
  LIST_CONCUR_DOCUMENTS_SUCCESS,
  LIST_CONCUR_DOCUMENTS_FAILURE,
  LIST_DOCANDSHARE_DOCUMENTS_REQUEST,
  LIST_DOCANDSHARE_DOCUMENTS_SUCCESS,
  LIST_DOCANDSHARE_DOCUMENTS_FAILURE,
  RESET_DOCANDSHARE_DOCUMENTS,
  RESET_CONCUR_DOCUMENTS,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
  CREATE_DOCUMENT_FAILURE,
  CREATE_DOCUMENT_RATING_REQUEST,
  CREATE_DOCUMENT_RATING_SUCCESS,
  CREATE_DOCUMENT_RATING_FAILURE,
  UPDATE_DOCUMENT_REQUEST,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAILURE,
  UPDATE_DOCUMENT_RATING_REQUEST,
  UPDATE_DOCUMENT_RATING_SUCCESS,
  UPDATE_DOCUMENT_RATING_FAILURE,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
  RESET_DOCUMENT,
  RESET_DOCUMENTS,
  RESET_DOCUMENTS_SEARCH_RESULTS,
} from "./types";

export const searchDocuments = (filter) => async (dispatch) => {
  dispatch({ type: SEARCH_DOCUMENTS_REQUEST });
  try {
    const query = await API.graphql(
      graphqlOperation(queries.searchByWordsAndDistance, { filter })
    );
    dispatch({
      type: SEARCH_DOCUMENTS_SUCCESS,
      payload: query.data.searchByWordsAndDistance.items,
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: SEARCH_DOCUMENTS_FAILURE,
      payload: e,
    });
  }
};

export const filterDocuments = (categoryId, name) => async (dispatch) => {
  if (!name) {
    dispatch(resetDocuments());
    dispatch(listDocuments(categoryId));
  } else {
    dispatch({
      type: FILTER_DOCUMENTS,
      name,
    });
  }
};

export const searchDocumentsUpdate = () => async (dispatch) => {
  dispatch({
    type: SEARCH_DOCUMENTS_UPDATE,
  });
};

export const listDocuments =
  (documentCategoryId, requestType) =>
  async (dispatch) => {
    var actionRequest, actionSuccess, actionFailure;
    switch (requestType) {
      case "concur":
        actionRequest = LIST_CONCUR_DOCUMENTS_REQUEST;
        actionSuccess = LIST_CONCUR_DOCUMENTS_SUCCESS;
        actionFailure = LIST_CONCUR_DOCUMENTS_FAILURE;
        break;

      case "docandshare":
        actionRequest = LIST_DOCANDSHARE_DOCUMENTS_REQUEST;
        actionSuccess = LIST_DOCANDSHARE_DOCUMENTS_SUCCESS;
        actionFailure = LIST_DOCANDSHARE_DOCUMENTS_FAILURE;
        break;
    
      default:
        actionRequest = LIST_DOCUMENTS_REQUEST;
        actionSuccess = LIST_DOCUMENTS_SUCCESS;
        actionFailure = LIST_DOCUMENTS_FAILURE;
        break;
    }
    dispatch({
      type: actionRequest,
    });
    try {
      var query;
      var data = [];
      if (!documentCategoryId) {
        query = await API.graphql(
          graphqlOperation(queries.listDocuments, {
            id: process.env.REACT_APP_MACROCATEGORY_ID,
          })
        );
        const categories = query.data.getMacrocategory.categories.items;
        for (var i = 0; i < categories.length; i++) {
          if (categories[i].documents) {
            data = [...data, ...categories[i].documents.items];
          }
        }
      } else {
        query = await API.graphql(
          graphqlOperation(queries.listDocumentsByCategory, {
            documentCategoryId,
          })
        );
        data = query.data.listDocumentsByCategory.items;
      }
      dispatch({
        type: actionSuccess,
        payload: data,
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: actionFailure,
        payload: e,
      });
    }
  };

export const getDocument = (id) => async (dispatch) => {
  dispatch({ type: GET_DOCUMENT_REQUEST });
  try {
    const query = await API.graphql(
      graphqlOperation(queries.getDocument, { id })
    );
    dispatch({
      type: GET_DOCUMENT_SUCCESS,
      payload: query.data.getDocument,
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: GET_DOCUMENT_FAILURE,
      payload: e,
    });
  }
};

export const createDocument = (input) => async (dispatch) => {
  dispatch({ type: CREATE_DOCUMENT_REQUEST });
  try {
    const mutation = await API.graphql(
      graphqlOperation(mutations.createDocument, {
        input: { ...omitBy(input, isEmpty) },
      })
    );
    dispatch({
      type: CREATE_DOCUMENT_SUCCESS,
      payload: mutation.data.createDocument,
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: CREATE_DOCUMENT_FAILURE,
      payload: e,
    });
  }
};

export const createDocumentRating = (input) => async (dispatch) => {
  dispatch({ type: CREATE_DOCUMENT_RATING_REQUEST });
  try {
    const mutation = await API.graphql(
      graphqlOperation(mutations.createDocumentRating, {
        input,
      })
    );
    dispatch({
      type: CREATE_DOCUMENT_RATING_SUCCESS,
      payload: mutation.data.createDocumentRating,
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: CREATE_DOCUMENT_RATING_FAILURE,
      payload: e,
    });
  }
};

export const updateDocumentRating = (input) => async (dispatch) => {
  dispatch({ type: UPDATE_DOCUMENT_RATING_REQUEST });
  try {
    const mutation = await API.graphql(
      graphqlOperation(mutations.updateDocumentRating, {
        input,
      })
    );
    dispatch({
      type: UPDATE_DOCUMENT_RATING_SUCCESS,
      payload: mutation.data.updateDocumentRating,
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: UPDATE_DOCUMENT_RATING_FAILURE,
      payload: e,
    });
  }
};

export const updateDocument = (input) => async (dispatch) => {
  dispatch({ type: UPDATE_DOCUMENT_REQUEST });
  try {
    const mutation = await API.graphql(
      graphqlOperation(mutations.updateDocument, {
        input: { ...omitBy(input, (value) => value === null) },
      })
    );
    dispatch({
      type: UPDATE_DOCUMENT_SUCCESS,
      payload: mutation.data.updateDocument,
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: UPDATE_DOCUMENT_FAILURE,
      payload: e,
    });
  }
};

export const deleteDocument = (input) => async (dispatch) => {
  dispatch({ type: DELETE_DOCUMENT_REQUEST });
  try {
    const mutation = await API.graphql(
      graphqlOperation(mutations.deleteDocument, { input })
    );
    dispatch({
      type: DELETE_DOCUMENT_SUCCESS,
      payload: mutation.data.deleteDocument,
    });
  } catch (e) {
    console.error(e);
    dispatch({
      type: DELETE_DOCUMENT_FAILURE,
      payload: e,
    });
  }
};

export const resetDocument = () => async (dispatch) => {
  dispatch({ type: RESET_DOCUMENT });
};

export const resetDocuments =
  (requestType) =>
  async (dispatch) => {
    var action;

    switch (requestType) {
      case "concur":
        action = RESET_CONCUR_DOCUMENTS;
        break;

      case "docandshare":
        action = RESET_DOCANDSHARE_DOCUMENTS;
        break;
    
      default:
        action = RESET_DOCUMENTS;
        break;
    }

    dispatch({ type: action });
  };

export const resetDocumentsSearchResults = () => async (dispatch) => {
  dispatch({ type: RESET_DOCUMENTS_SEARCH_RESULTS });
};

export const listRiskDocuments = async (categoryId) => {
  try {
    var query;
    var data = [];

    if (!categoryId) {
      query = await API.graphql(
        graphqlOperation(queries.listRiskDocuments, {
          id: process.env.REACT_APP_RISKS_MACROCATEGORY_ID,
        })
      );
      const categories = query.data.getMacrocategory.categories.items;
      for (var i = 0; i < categories.length; i++) {
        if (categories[i].documents) {
          data = [...data, ...categories[i].documents.items];
        }
      }
    } else {
      query = await API.graphql(
        graphqlOperation(queries.listRiskDocumentsByCategory, {
          documentCategoryId: categoryId,
        })
      );
      data = query.data.listDocumentsByCategory.items;
    }

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const createRiskDocument = async (name, categoryId) => {
  try {
    const mutation = await API.graphql(
      graphqlOperation(mutations.createRiskDocument, {
        input: {
          name,
          documentCategoryId: categoryId,
          state: "APPROVED",
          expireOn: "2999-01-01T00:00:00.000Z",
        },
      })
    );
    return mutation.data.createDocument;
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteRiskDocument = async (documentId) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.deleteRiskDocument, {
        input: { id: documentId },
      })
    );
    return;
  } catch (e) {
    throw new Error(e);
  }
};
