import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center'
    },
    titleElement: {
        display: 'flex',
        alignItems: 'center'
    },
    back: {
        marginRight: theme.spacing(2)
    },
    add: {
        marginLeft: theme.spacing(2)
    },
    title: {
        marginLeft: theme.spacing(1)
    }
}));

export default useStyles;