import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import useStyles from './Upload.style';

export default function Upload({onChange, value}) {
  const [ files, setFiles ] = useState(value);
  const classes = useStyles();

  useEffect(() => {
    onChange(files);
  }, [files, onChange])

  const filesToArray = (fileList) => {
    return Array.prototype.slice.call(fileList);
  }

  const handleDelete = (fileIndex) => {
    var fileList = [...files];
    fileList.splice(fileIndex, 1);
    setFiles(fileList);
  }

  const handleChange = (event) => {
    const fileList = event.target.files;
    setFiles(filesToArray(files).concat(filesToArray(fileList)))
  }

  const Files = () => (
    <Grid item>
      {
        files.map((file, index) => (
          <Chip
            key={index}
            label={file.name}
            onDelete={() => handleDelete(index)}
            color="secondary"
            className={classes.chip}
          />
        ))
      }
    </Grid>
  )
  
  return (
    <>
      <input
        accept="*/*"
        style={{display: 'none'}}
        id="upload-files"
        multiple
        type="file"
        onChange={handleChange} 
      />
      <Grid container spacing={2}>
        <Grid item xs>
          <label htmlFor="upload-files">
            <Button variant="contained" component="span" disableElevation>
              Upload
            </Button>
          </label>
        </Grid>
        <Files />
      </Grid>
    </>
  );
}

Upload.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array
}

Upload.defaultProps = {
  onChange: () => {},
  value: []
}