/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePosition } from 'use-position';
import i18n from 'i18n-js';

import {
  searchDocuments,
  filterDocuments,
  resetDocumentsSearchResults,
  searchDocumentsUpdate
} from '../models/actions/documentActions';

import debounce from 'lodash.debounce';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// import useStyles from './SearchInput.style';

export default function SearchInput({categoryId}) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories.items);
  const category = useSelector(state => state.categories.item);
  const searchResults = useSelector(state => state.documents.searchResults);
  const [ value, setValue ] = useState(null)
  const [ query, setQuery ] = useState(null);
  const {
    latitude,
    longitude,
    error
  } = usePosition();

  const getCategoriesIds = useCallback(() => {
    if (!category.id && !categoryId) {
      return categories.map( item => item.id );
    } else {
      return ( category.id ? [category.id] : [ categoryId ] );
    }
    
  }, [categories, category, categoryId]);

  const searchByDistance = useCallback(() => {
    if(!error && latitude && longitude) {

      const ids = getCategoriesIds();

      const filter = {
        text: { contains: "" },
        location: { longitude, latitude },
        km: 100,
        documentCategoryIds: ids
      };
      dispatch( searchDocuments(filter) );
    }
  }, [latitude, longitude, error, dispatch, getCategoriesIds])

  const handleSelected = (newValue) => {
    var { id } = category;
    if(!id) {
      id = categoryId
    }

    setValue(newValue);
    if(!newValue) {
      searchByDistance();
    }
    dispatch( filterDocuments(id, newValue) );
  }

  const handleInputChange = (newValue) => {
    debouncedSearch(newValue);
  }

  const debouncedSearch = debounce(query => {
    setQuery(query);
  }, 1000);

  useEffect(() => {
    dispatch( resetDocumentsSearchResults() );
    searchByDistance();
  }, [dispatch, searchByDistance])

  useEffect(() => {
    if ( value ) {
      dispatch( searchDocumentsUpdate() );
    }
  }, [dispatch, searchResults, value ])

  useEffect(() => {
    if(query) {

      const ids = getCategoriesIds();

      const filter = {
        text: { contains: query },
        documentCategoryIds: ids
      };
      dispatch( searchDocuments(filter) );
    }
  }, [query, dispatch, getCategoriesIds])

  const renderLocationError = () => {
    if(!error) {
      return null;
    }
    if(error === "User denied Geolocation") {
      return (
        <div>{i18n.t("locationError")}</div>
      )
    }
  }

  return (
    <>
      {renderLocationError()}
      <Autocomplete
        fullWidth
        onInputChange={(e, newInputValue) => {
          handleInputChange(newInputValue)
        }}
        id="search-input"
        freeSolo={true}
        value={value}
        onChange={(e, newValue) => handleSelected(newValue)}
        options={searchResults.map(option => option.name)}
        filterOptions={(option) => option}
        style={{ width: 300 }}
        noOptionsText={i18n.t("searchEmpty")}
        renderInput={(params) => (
          <TextField
            {...params}
            label={i18n.t("searchLabel")}
            margin="normal"
            variant="outlined"
          />
        )}
      />
    </>
  );
}