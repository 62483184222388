import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import { getCategory } from '../../models/actions/categoryActions';

import Can from '../../config/Can';

import TitleBarSkeleton from './components/TitleBarSkeleton/TitleBarSkeleton';
import SearchInput from '../SearchInput';
import useStyles from './TitleBar.style';

export default function TitleBar({onAddClick}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    let { id } = useParams();
    
    const convention = useSelector(state => state.categories.item);
  
    const fetchData = useCallback(() => {
      dispatch( getCategory(id) )
    }, [dispatch, id])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const TitleDescription = () => {
        if(!convention.id) {
            return <TitleBarSkeleton />;
        }
        return (
            <>
                <Grid item className={classes.titleElement}>
                    <Icon style={{color: convention.color}}>
                        {convention.icon}
                    </Icon>
                </Grid>
                <Grid item className={clsx(classes.titleElement, classes.title)}>
                    <Typography variant="h5">{navigator.language !== "it-IT" ? (convention.nameLocalized.en || convention.name) : convention.name}</Typography>
                </Grid>
            </>
        );
    }

    const Title = () => {
        return (
            <Grid item xs={12} sm={6} className={classes.container}>
                <Grid container>
                    <Grid item className={clsx(classes.titleElement, classes.back)}>
                        <IconButton onClick={() => history.push("/")}>
                            <Icon>navigate_before</Icon>
                        </IconButton>
                    </Grid>
                    <TitleDescription />
                    <Can I="view" a="DocumentAddButton">
                        <Grid item className={clsx(classes.titleElement, classes.add)}>
                            <IconButton
                                color="primary"
                                onClick={onAddClick}
                            >
                                <Icon>add</Icon>
                            </IconButton>
                        </Grid>
                    </Can>
                </Grid>
            </Grid>
        );
    };

    const Search = () => (
        <Grid item xs={12} sm="auto" className={classes.container}>
            <SearchInput />
        </Grid>
    )

    return (
        <Grid container spacing={2} justify="space-between">
            <Title />
            <Search />
        </Grid>
    );
}

TitleBar.propTypes = {
    onAddClick: PropTypes.func
}

TitleBar.defaultProps = {
    onAddClick: () => {}
}