/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  //ConventionPage.js
  newTitle: "Nuova Convenzione",
  editTitle: "Modifica Convenzione",

  //Conventions.js
  conventionsTitle: "Convenzioni",

  //Login.js
  buttonText: "AUTORIZZA L'APP",

  //HotelBar.js
  selectLabel: "Paese",
  addButtonText: "Aggiungi",
  selectCountryLabel: "Seleziona il paese",

  //HotelPage.js
  emptyText: "Nessun paese selezionato",
  policyTitle: "Travel Policy",
  policyButton: "Vai alla Travel Policy",
  concurDocumentsTitle: "Concur",
  documentsTitle: "Documenti",
  insurancePoliciesTitle: "Polizze Assicurative",
  noDocumentsText: "Nessun documento trovato",
  trmCourseTitle: "ISCRIZIONE CORSO TRM",
  trmCourseText: [
    "Per iscriverti al corso TRM, ti chiediamo gentilmente di scrivere una mail al seguente indirizzo: ",
    "trmlearning.external@valentino.com ",
    "compilando ed inviando il file che trovi a questo",
    " link.",
    "Una volta fatto ti chiediamo di seguire le indicazioni che riceverai via mail per poter accedere.",
  ],

  //SearchInput.js
  locationError: "Localizzazione non abilitata",
  searchLabel: "Cerca",
  searchEmpty: "Nessuna opzione",

  //UserModal.js
  informationText: "Informazioni",
  contactsText: "Contatti",
  notesText: "Note",
  addressText: "Indirizzo",
  mapsText: "Apri in Google Maps",

  //NewDocumentModal.js
  submitValidation: "*Nome e File sono obbligatori.",
  modalButtonText: "Aggiungi",
  modalTitle: "Aggiungi un documento",
  modalInfo:
    "Scegli un nome ed allega un file o un link per creare un nuovo documento.",
  modalInfoRisk:
    "Scegli un nome ed allega un file per creare un nuovo documento.",
  modalInputPlaceholder: "Nome",
  modalType: "Tipologia",
  modalDropzoneText: "Trascina un file clicca qui",
  modalBackButtonText: "Annulla",
  modalSubmitButtonText: "Aggiungi",

  //Form.js
  formNameLabel: "Nome",
  formNamePlaceholder: "Inserisci un nome",
  formDescriptionLabel: "Descrizione",
  formDescriptionPlaceholder: "Inserisci una descrizione",
  formLocationLabel: "Posizione",
  formAddressLabel: "Indirizzo",
  formAddressPlaceholder: "Inserisci l'indirizzo",
  formProvinceLabel: "Provincia",
  formProvincePlaceholder: "Inserisci la provincia",
  formEmailPlaceholder: "Inserisci l'indirizzo email",
  formPhoneLabel: "Telefono",
  formPhonePlaceholder: "Inserisci il numero di telefono",
  formWebsiteLabel: "Sito Web",
  formWebsitePlaceholder: "Inserisci il sito web",
  formPriceRangeLabel: "Fascia di Prezzo",
  formPriceRangeDescriptionPlaceholder: "Inserisci informazioni aggiuntive",
  formHasRestaurantLabel: "Con Ristorante",
  formBookingOptionLabel: "Prenotazione via",
  formNotesLabel: "Note",
  formNotesPlaceholder: "Inserisci delle note",
  formStatusLabel: "Stato",
  formStatusApproved: "Approvato",
  formStatusDraft: "Bozza",
  formExpirationLabel: "Scadenza",
  formDocumentsLabel: "Documenti",
  formDeleteButtonText: "ELIMINA",
  formAddButtonText: "Aggiungi",
  formUpdateButtonText: "Aggiorna",
  formUploadedFilesLabel: "Files Caricati",

  //CTableHead.js
  tableNameLabel: "Nome",
  tableStatusLabel: "Stato",
  tableProvinceLabel: "Provincia",
  tablePositionLabel: "Posizione",
  tableRatingLabel: "Valutazione Complessiva",
  tableUserRatingLabel: "Valutazione Utente",
  tableExpirationLabel: "Scadenza",
  tableNotesLabel: "Note",
  tablePriceRangeLabel: "Prezzo",
  tableHasRestaurantLabel: "Ristorante",
  tableHasRestaurantAvailable: "Disponibile",
  tableHasRestaurantUnavailable: "Non disponibile",
  tableHasRestaurantUnknown: "",
  tableBookingOptionLabel: "Prenotazione via",
  tableBookingConcurLabel: "Concur",
  tableBookingEmailLabel: "Email",

  //CTable.js
  tableExpiringText: "IN SCADENZA",
  tableDraftText: "BOZZA",
  tableApprovedText: "APPROVATO",
  tableExpiredText: "SCADUTO",
  tableUserRatingText: "Inserisci la tua valutazione",
  tableExpiringDays: "Scade tra {{days}} giorni",
  tableExpirationDays: "Scaduto da {{days}} giorni",

  //DeleteModal.js
  deleteModalTitle: "Eliminare il documento?",
  deleteModalInfo: "Procedere con l'eliminazione di questo documento?",
  deleteModalBackButtonText: "Annulla",
  deleteModalSubmitButtonText: "Elimina",
};
