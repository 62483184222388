import React from "react";
import PropTypes from "prop-types";

import i18n from "i18n-js";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default function CTableHead(props) {
  const { order, orderBy, onRequestSort, CanComponent, hasHotels } = props;

  const headCells = [
    { id: "name", label: i18n.t("tableNameLabel") },
    { id: "state", label: i18n.t("tableStatusLabel") },
    { id: "province", label: i18n.t("tableProvinceLabel") },
    { id: "address", label: i18n.t("tablePositionLabel") },
    { id: "properties.priceRange.value", label: i18n.t("tablePriceRangeLabel") },
    { id: "properties.hasRestaurant", label: i18n.t("tableHasRestaurantLabel") },
    { id: "userRating", label: i18n.t("tableUserRatingLabel") },
    { id: "rating", label: i18n.t("tableRatingLabel") },
    { id: "properties.bookingOption", label: i18n.t("tableBookingOptionLabel") },
    { id: "expireOn", label: i18n.t("tableExpirationLabel") },
    { id: "note", label: i18n.t("tableNotesLabel") },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const Cell = ({ headCell }) => {
    if (headCell.id === "expireOn" && hasHotels) {
      return null;
    }
    if (headCell.id === "note" && hasHotels) {
      return null;
    }

    if (headCell.id === "properties.bookingOption" && !hasHotels) {
      return null;
    }
    if (headCell.id === "properties.hasRestaurant" && !hasHotels) {
      return null;
    }
    if (headCell.id === "properties.priceRange.value" && !hasHotels) {
      return null;
    }

    const content = (
      <TableCell sortDirection={orderBy === headCell.id ? order : false}>
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
        </TableSortLabel>
      </TableCell>
    );

    if (headCell.id === "state") {
      return <CanComponent>{content}</CanComponent>;
    }

    return content;
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <Cell key={headCell.id} headCell={headCell} />
        ))}
      </TableRow>
    </TableHead>
  );
}

CTableHead.propTypes = {
  CanComponent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  hasHotels: PropTypes.bool,
};

CTableHead.defaultProps = {
  hasHotels: false,
};
