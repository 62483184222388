import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650
    },
    name: {
        cursor: 'pointer'
    },
    state: {
        fontWeight: 700
    },
    draft: {
        color: 'orange'
    },
    approved: {
        color: 'green'
    },
    expired: {
        color: 'red'
    },
    expiring: {
        color: 'red'
    },
    rating: {
        display: 'flex',
        alignItems: 'center'
    },
    ratingText: {
        marginRight: theme.spacing(1)
    },
    userRating: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    userRatingText: {
        marginTop: theme.spacing(1),
        fontSize: theme.typography.caption.fontSize
    },
    address: {
        maxWidth: 150
    },
    restaurantText: {
        marginLeft: theme.spacing(1)
    },
}));

export default useStyles;