import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  listDocuments,
  resetDocuments,
  createDocumentRating,
  updateDocumentRating,
} from "../../../models/actions/documentActions";
import Can from "../../../config/Can";
import CTable from "../../../components/CTable/CTable";

export default function CTableContainer({
  hasHotels,
  onNameClick,
  categoryId,
}) {
  const dispatch = useDispatch();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("nome");
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  const userMode = useSelector((state) => state.auth.userMode);
  const documents = useSelector((state) => state.documents.items);

  const descendingComparator = (a, b, orderBy) => {

    const getValue = ( obj, attributes ) => {
      if ( attributes.length > 1 ) {
        const field = attributes.shift();
        return getValue( obj[field], attributes  );
      } else {
        if ( Array.isArray( obj[ attributes[0] ] ) ) {
          return obj[ attributes[0] ][0];
        } else {
          return obj[ attributes[0] ];
        }
      }
    }
    a = getValue( a, orderBy.split('.') );
    b = getValue( b, orderBy.split('.') );
    if (a < b) {
      return 1;
    }
    if (a > b) {
      return -1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRatingCreate = (input) => {
    dispatch(createDocumentRating(input));
  };

  const handleRatingUpdate = (input) => {
    dispatch(updateDocumentRating(input));
  };

  const fetchData = useCallback(() => {
    dispatch(resetDocuments());
    if (categoryId) {
      dispatch(listDocuments(categoryId));
    } else {
      dispatch(listDocuments());
    }
  }, [dispatch, categoryId]);

  useEffect(() => {
    fetchData();
  }, [fetchData, categoryId]);

  useEffect(() => {
    setFilteredDocuments(documents);
  }, [documents]);

  useEffect(() => {
    if (documents && userMode) {
      setFilteredDocuments(documents.filter((d) => d.state === "APPROVED"));
    } else {
      setFilteredDocuments(documents);
    }
  }, [documents, userMode]);

  useEffect(() => {
    dispatch(resetDocuments());
  }, [dispatch]);

  const CanComponent = ({ children }) => (
    <Can I="view" a="DocumentsTableState">
      {children}
    </Can>
  );

  return (
    <CTable
      hasHotels={hasHotels}
      CanComponent={CanComponent}
      order={order}
      orderBy={orderBy}
      documents={filteredDocuments}
      compare={getComparator}
      sort={stableSort}
      onSorting={handleRequestSort}
      onNameClick={onNameClick}
      onCreateRating={handleRatingCreate}
      onUpdateRating={handleRatingUpdate}
    />
  );
}

CTableContainer.propTypes = {
  categoryId: PropTypes.string,
  onNameClick: PropTypes.func,
  hasHotels: PropTypes.bool,
};

CTableContainer.defaultProps = {
  categoryId: null,
  onNameClick: () => {},
  hasHotels: false,
};
