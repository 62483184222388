export const createDocument = `mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      name
      description
      location
      address
      province
      email
      phone
      website
      note
      state
      expireOn
      rating
      voteCasts
      properties {
        bookingOption
        hasRestaurant
        priceRange {
          value
          currency
          description
        }
      }
      ratings {
        items {
          documentId
          userId
          score
        }
      }
      files {
        items {
          id
          title
          s3File {
            s3PresignedUrl
          }
        }
      }
    }
}`;

export const updateDocument = `mutation UpdateDocument(
  $input: UpdateDocumentInput!
) {
  updateDocument(input: $input) {
    id
    name
    description
    location
    address
    province
    email
    phone
    website
    note
    state
    expireOn
    rating
    voteCasts
    properties {
      bookingOption
      hasRestaurant
      priceRange {
        value
        currency
        description
      }
    }
    ratings {
      items {
        documentId
        userId
        score
      }
    }
    files {
      items {
        id
        title
        s3File {
          s3PresignedUrl
        }
      }
    }
  }
}`;

export const deleteDocument = `mutation DeleteDocument(
  $input: DeleteDocumentInput!
) {
  deleteDocument(input: $input) {
    id
  }
}`;

export const createDocumentRating = `mutation CreateDocumentRating(
  $input: CreateDocRatInput!
) {
  createDocumentRating(input: $input) {
    documentId
    userId
    score
  }
}`;

export const updateDocumentRating = `mutation UpdateDocumentRating(
  $input: UpdateDocRatInput!
) {
  updateDocumentRating(input: $input) {
    documentId
    userId
    score
  }
}`;

export const createPresignedUrl = `mutation createPresignedUrl(
  $input: CreateUploadPresignedUrlInput!
) {
  createPresignedUrl(input: $input) {
    presignedUrl
    key
    bucket
  }
}`;

export const createFile = `mutation createFile($input: CreateFileInput!) {
  createFile(input: $input) {
    id
    title
  }
}`;

export const deleteFile = `mutation deleteFile($input: DeleteFileInput!) {
  deleteFile(input: $input) {
    id
  }
}`;

export const createLink = `mutation CreateLink(
  $input: CreateLinkInput!
) {
  createLink(input: $input) {
    id
    name
    url
  }
}`;

export const createRiskDocument = `mutation CreateDocument(
  $input: CreateDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  createDocument(input: $input, condition: $condition) {
    id
  }
}`;

export const deleteRiskDocument = `mutation DeleteDocument(
$input: DeleteDocumentInput!
) {
deleteDocument(input: $input) {
  id
}
}`;
