import {
  CHECK_SESSION_SUCCESS,
  CHECK_SESSION_FAILURE,
  TOGGLE_USERMODE,
} from "../actions/types";

const initialState = {
  session: null,
  authenticated: null,
  group: "",
  userMode: true,
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  switch (action.type) {
    case CHECK_SESSION_SUCCESS:
      const claims = action.payload.idToken.payload;

      return {
        ...state,
        group: claims["cognito:groups"][0], //Admin group
        authenticated: true,
        session: action.payload,
      };
    case CHECK_SESSION_FAILURE:
      return {
        ...state,
        authenticated: false,
      };
    case TOGGLE_USERMODE:
      return {
        ...state,
        userMode: !state.userMode,
      };
    default:
      return state;
  }
}
