import React from 'react';

import i18n from 'i18n-js';

import Router from './router';
import en from './i18n/en';
import it from './i18n/it';
import './App.css';

i18n.fallbacks = true;
i18n.translations = { en, it };
i18n.locale = navigator.language || navigator.userLanguage;

function App() {
  return (
    <div className="app">
      <Router />
    </div>
  );
}

export default App;